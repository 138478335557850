// Text truncate mixins
// Version 1.0.0
//
// Custom mixins for text truncate

@mixin text-truncate-multiline($line) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;    

  p {
    display: inline;
  }
}
