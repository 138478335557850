/*
 * Antikode main.scss
 * Version - 3.0.1
 * Bootstrap - 4.5.0
 * 2021 Antikode
 */

// ========================================================================================================================================
// Import
// ========================================================================================================================================

// ==========================================================================
// Bootstrap Base & Custom Variables
// ==========================================================================

// Required functions / mixins used in variables config
@import "~bootstrap/scss/functions"; // DO NOT EDIT
@import "functions/strip-sass"; // e.g. strip unit in SASS
@import "mixins/fonts-custom"; // DO NOT EDIT

// Custom variables
// EDIT THESE VARIABLES
@import "variables/color"; // Set base color variables
@import "variables/options"; // Enable base rounded / shadows
@import "variables/spacing-grid";
@import "variables/border-shadow-modal"; // Set base border, box shadow, and modal variables
@import "variables/typography"; // Set custom fonts and base typography variables
@import "variables/buttons-forms"; // Set buttons / forms base padding and other variables
@import "variables/breadcrumbs";
@import "variables/transitions";
@import "variables/animations"; // Set general animation duration
@import "variables/navbar"; // Set navbar height, animation duration, and other variables

// Base styles
@import "base/bootstrap.scss"; // Bootstrap 4 base variables, mixins, and styles

// ==========================================================================
// Optional Plugins
// ==========================================================================

// Font Awesome 5.13.0
// @import "../plugins/fontawesome-pro/css/all.min.css";
// @import "../plugins/fontawesome-pro/scss/fontawesome.scss";

// Anti Icons 1.0.0
@import "../plugins/anti-icons/variables";
@import "../plugins/anti-icons/light/style.css";
@import "../plugins/anti-icons/regular/style.css";
@import "../plugins/anti-icons/solid/style.css";
@import "../plugins/anti-icons/brands/style.css";

// // Icomoon
// @import "../plugins/icomoon/style.css";

// Slick Carousel
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

// ==========================================================================
// Antikode Custom Mixins, Utilities, Overriders, and Base
// ==========================================================================

// Custom Mixins
@import "mixins/gradients"; // Gradients mixns
@import "mixins/icons"; // Font awesome mixins
@import "mixins/prefix"; // Vendor-prefixed CSS helper
@import "mixins/position"; // Shorthandizes position declarations
@import "mixins/text-truncate"; // Text truncate multiline

// Plugin Style Overrider
@import "overrider/datatable";
@import "overrider/select2";

// Utilities
@import "utilities/background"; // e.g. .bg-overlay
@import "utilities/buttons"; // e.g. .btn-block-mw-100px, .btn-block-mw-200px, etc.
@import "utilities/grid"; // e.g. .row-0, .row-1, .row-10px, .row-20px, etc.
@import "utilities/position"; // e.g. .center-both, etc.
@import "utilities/sizing"; // e.g. .h-50px, .w-md-100-px, etc.
@import "utilities/spacing"; // e.g. .py-main, .mb-sm-down-1, etc.
@import "utilities/text"; // e.g. .text-truncate-twoline, etc.
@import "utilities/img"; // e.g. .text-truncate-twoline, etc.

// SCSS base component
@import "base/alert";
@import "base/animations";
@import "base/background";
@import "base/dropdown";
@import "base/forms";
@import "base/nav";
@import "base/post";
@import "base/reboot";
@import "base/table";

// ==========================================================================
// Anti Components
// ==========================================================================

@import "../../components/anti/accordion/accordion";
@import "../../components/anti/accordion/accordion-custom";
@import "../../components/anti/breadcrumb/breadcrumb";
@import "../../components/anti/breadcrumb/breadcrumb-custom";
@import "../../components/anti/buttons/buttons";
@import "../../components/anti/buttons/buttons-custom";
@import "../../components/anti/card/card";
@import "../../components/anti/card/card-custom";
@import "../../components/anti/slider/slider";
@import "../../components/anti/slider/slider-custom";
@import "../../components/anti/slider-duo/slider-duo";
@import "../../components/anti/slider-duo/slider-duo-custom";
@import "../../components/anti/cover/cover";
@import "../../components/anti/cover/cover-custom";
@import "../../components/anti/cursor/cursor";
@import "../../components/anti/cursor/cursor-custom";
@import "../../components/anti/demo/demo";
@import "../../components/anti/download/download";
@import "../../components/anti/download/download-custom";
@import "../../components/anti/footer/footer";
@import "../../components/anti/footer/footer-custom";
@import "../../components/anti/feature/feature";
@import "../../components/anti/feature/feature-custom";
@import "../../components/anti/forms/forms";
@import "../../components/anti/forms/forms-custom";
@import "../../components/anti/image/image";
@import "../../components/anti/image/image-custom";
@import "../../components/anti/link/link";
@import "../../components/anti/link/link-custom";
@import "../../components/anti/loader/loader";
@import "../../components/anti/loader/loader-custom";
// @import "../../components/anti/map/map";
// @import "../../components/anti/map/map-custom";
@import "../../components/anti/modal/modal";
@import "../../components/anti/modal/modal-custom";
@import "../../components/anti/nav-bottom/nav-bottom";
@import "../../components/anti/nav-bottom/nav-bottom-custom";
@import "../../components/anti/navbar/navbar";
@import "../../components/anti/navbar/navbar-custom";
@import "../../components/anti/newsletter/newsletter";
@import "../../components/anti/newsletter/newsletter-custom";
@import "../../components/anti/page-transition/page-transition";
@import "../../components/anti/page-transition/page-transition-custom";
@import "../../components/anti/post/post";
@import "../../components/anti/post/post-custom";
@import "../../components/anti/social/social";
@import "../../components/anti/social/social-custom";
@import "../../components/anti/stats/stats";
@import "../../components/anti/stats/stats-custom";
@import "../../components/anti/tabs/tabs";
@import "../../components/anti/tabs/tabs-custom";
@import "../../components/anti/type/type";
@import "../../components/anti/type/type-custom";
@import "../../components/anti/utils/utils";
@import "../../components/anti/video/video";

// ==========================================================================
// Gutenberg Components
// ==========================================================================

// REMOVE IF UNUSED
// @import "../../components/gutenberg/acf-label-w-value-list/label-w-value-list";
// @import "../../components/gutenberg/acf-download/download";
// @import "../../components/gutenberg/shortcode/post-list/post-list";
// @import "../../components/gutenberg/shortcode/project-list/project-list";

// ==========================================================================
// Pages Styles
// ==========================================================================
// Main Pages
@import "pages/home";
@import "pages/about";
@import "pages/contact";
@import "pages/term-privacy";
@import "pages/glossary";
@import "pages/investment";
@import "pages/search";
@import "pages/news-analysis";
@import "pages/news-detail";
@import "pages/404";

// ========================================================================================================================================
// Custom Styles
// ========================================================================================================================================
// CAK COMPONENT
@import "../../components/cak/bottom-nav/bottom-nav";
@import "../../components/cak/banner-slider/banner-slider";
@import "../../components/cak/marquee-stocks/marquee-stocks";
@import "../../components/cak/subscription/subscription";
@import "../../components/cak/pagination/pagination-cak";
@import "../../components/cak/list-news/list-news";
@import "../../components/cak/list-news-square/list-news-square";
@import "../../components/cak/story/story";
@import "../../components/cak/referral/referral";
@import "../../assets/scss/overrider/toast";
// ==========================================================================
// General
// ==========================================================================

// Insert custom style here

.section-style {
  background-color: $black;
  position: relative;
  z-index: 1;
}

#nprogress {
  z-index: 4000;
  .bar {
    background: $cak-yellow !important;
  }
  .spinner-icon {
    display: none !important;
    border-top-color: $cak-yellow !important;
    border-left-color: $cak-black !important;
  }
}

.wrapper-page {
  background-color: $cak-black;
  position: relative;
  z-index: 1;
  @include media-breakpoint-up(lg) {
    min-height: calc(
      100vh - 72px - 100px
    ); // 72px is height of navbar and 100 px is margin top that given to <main>
  }
}

.img-full-inside {
  img {
    width: 100%;
  }
}

main {
  overflow-x: initial;
}

.min-h-lg-screen {
  @include media-breakpoint-up(lg) {
    min-height: 100vh;
  }
}