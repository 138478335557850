.sc-subscription {
  position: relative;
  background-image: url("../../../assets/img/grfx/grid-bg-big.png");
  background-repeat: no-repeat;
  background-size: cover;
  // transition: transform .3s;

  &.no-grid-bg {
    background-image: none !important;
  }


  .sc-subscription-badge {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 94px;
    height: 94px;
    top: calc(-94px / 2);
    right: 12px;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 94px;
      height: 94px;
    }
  }

  .sc-subscription-content {
    width: clamp(100%, 312px, calc(100% - (128px / 2)));
    // width: 100%;
    padding: 0 1rem;
    padding-bottom: 2rem;
    padding-top: calc(94px / 2);
    position: relative;

    &.bg-cak-blue {
      background-color: $cak-blue;
    }

    &.bg-cak-blue-3 {
      background-color: $cak-blue-3;
    }
  }

  .subscription-btn-submit {
    .btn {
      padding: .5rem 3rem;
      background-color: $cak-gray-1;
      color: $white;
      border-color: $cak-black;
    }
  }

  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-image: url("../../../assets/img/grfx/grid-bg-big-desktop.png");

    .sc-subscription-badge {
      width: 128px;
      height: 128px;
      left: calc(-128px / 2);
      top: 36px;

      img {
        width: 128px;
        height: 128px;
      }

      &.in-newsletter {
        width: 144px;
        height: 110px;
        left: calc(-144px / 2);

        img {
          width: 144px;
          height: 110px;
        }
      }
    }

    .sc-subscription-content {
      padding: 36px 0;
      padding-right: 60px;
      padding-left: calc((128px / 2) + 32px);
      width: clamp(50%, 86.66vw, calc(100% - (128px / 2)));
    }

    .subscription-btn-submit {
      .btn {
        padding: .8rem 3rem;
      }
    }
  }
}

.input-phonenumber {
  .circle-flag {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #E2E2E2;

    img {
      transform: scale(2.2) translateY(-2px);
    }
  }

  .input-phonenumber-field {
    background-color: $white;
    border: 1px solid $cak-black;
    padding-left: 1rem;

    .invalid-input {
      color: $cak-red;
    }

    .input-phonenumber-field-prefix {
      padding: 12px 0;
    }

    .barrier {
      padding: 12px 0;
      margin: 0 10px;

      &::after {
        position: relative;
        display: block;
        width: 1px;
        height: 24px;
        background-color: rgba($cak-black, .2);
        content: "";
      }
    }

    input {
      border: none;
      width: 100%;

      &:focus {
        outline: none;
      }
    }
  }

  &.input-phonenumber-white {
    .input-phonenumber-field {
      background-color: $cak-black;
      border-color: rgba($white, .3) !important;

      .barrier {
        &::after {
          background-color: rgba($white, .3);
        }
      }

      input {
        background-color: $cak-black;

        &:not(.invalid-input) {
          color: $white;
        }
      }
    }
  }
}