// ========================================================================================================================================
// Buttons - Custom
// ========================================================================================================================================

// ==========================================================================
// Hover Color
// ==========================================================================

// Set hover darken color (in %)
$btn-hover-bg-darken-custom: $btn-hover-bg-darken; // EDIT THIS AS NEEDED (Default: 5%)

.btn {

  @each $color,
  $value in $theme-colors {
    &.btn-outline-#{$color} {
      @include hover {
        background-color: darken($value, $btn-hover-bg-darken-custom);
        border-color: darken($value, $btn-hover-bg-darken-custom);
      }
    }

    &.btn-#{$color} {
      @include hover {
        background-color: darken($value, $btn-hover-bg-darken-custom);
        border-color: darken($value, $btn-hover-border-darken);
      }
    }
  }
}

.btn {
  font-weight: $font-weight-semibold;
  font-family: $headings-font-family;
  text-transform: uppercase;

  &.btn-normal-outline {
    border: 1px solid $white;
    color: $white;
  }

  &:not(.btn-normal) {
    clip-path: polygon(0px 4px,
        2px 4px,
        2px 2px,
        4px 2px,
        4px 0px,
        calc(100% - 4px) 0px,
        calc(100% - 4px) 2px,
        calc(100% - 2px) 2px,
        calc(100% - 2px) 4px,
        100% 4px,
        100% calc(100% - 4px),
        calc(100% - 2px) calc(100% - 4px),
        calc(100% - 2px) calc(100% - 2px),
        calc(100% - 4px) calc(100% - 2px),
        calc(100% - 4px) 100%,
        4px 100%,
        4px calc(100% - 2px),
        2px calc(100% - 2px),
        2px calc(100% - 4px),
        0px calc(100% - 4px));
    position: relative;
  }

  &.bold-border {
    border-color: $white;
    border-style: solid;
    border-top-width: 2px;
    border-left-width: 2px;
    border-right-width: 4px;
    border-bottom-width: 4px;
  }

  &.btn-blue {
    color: $white;
    background-color: $cak-blue-2;
    // border-color: $cak-blue-2;
    border: none !important;
    clip-path: polygon(0% 15%, 4% 15%, 4% 7%, 8% 7%, 8% 0%, 92% 0%, 92% 7%, 96% 7%, 96% 15%, 100% 15%, 100% 85%, 96% 85%, 96% 93%, 92% 93%, 92% 100%, 8% 100%, 8% 93%, 4% 93%, 4% 85%, 0% 85%);

    &::before {
      content: "";
      position: absolute;
      left: 0%;
      top: 10%;
      width: 100%;
      height: 81%;
      background-color: $cak-blue;
      clip-path: polygon(0% 15%, 4% 15%, 4% 7%, 8% 7%, 8% 0%, 92% 0%, 92% 7%, 96% 7%, 96% 15%, 100% 15%, 100% 85%, 96% 85%, 96% 93%, 92% 93%, 92% 100%, 8% 100%, 8% 93%, 4% 93%, 4% 85%, 0% 85%);
      z-index: 0;
    }
  }
}

// ==========================================================================
// Hover Effect
// ==========================================================================

// Toggle hover block right effect for filled & outline buttons
//
// .btn:not(.btn-link) {
//   @include hover-blockRight;
// }

// Toggle hover underline effect for link buttons
//
// .btn-link {
//   @include hover-underline;
//   &:before {
//     bottom: 0.25rem; // underline effect position (default: 0)
//   }
// }

// ==========================================================================
// General
// ==========================================================================

// Add customs styles here
[role="button"] {
  outline: none;
}

.btn {
  &.btn-custom-light {
    clip-path: polygon(0px 4px,
        2px 4px,
        2px 2px,
        4px 2px,
        4px 0px,
        calc(100% - 4px) 0px,
        calc(100% - 4px) 2px,
        calc(100% - 2px) 2px,
        calc(100% - 2px) 4px,
        100% 4px,
        100% calc(100% - 4px),
        calc(100% - 2px) calc(100% - 4px),
        calc(100% - 2px) calc(100% - 2px),
        calc(100% - 4px) calc(100% - 2px),
        calc(100% - 4px) 100%,
        4px 100%,
        4px calc(100% - 2px),
        2px calc(100% - 2px),
        2px calc(100% - 4px),
        0px calc(100% - 4px));
    background-color: $white;
    margin: 0;
    border: 1px solid $white;
    padding: 6px 12px;

    &::before,
    &::after {
      content: none;
    }
  }

  &.btn-outline-light {
    // background-color: transparent;
    margin: 0;
    // border: 1px solid $white;
    padding: 6px 12px;

    border: none;
    background-color: $white;
    position: relative;
    transition: opacity .3s;

    &:hover {
      opacity: .8;
      color: $white;
    }

    &::before {
      content: "";
      clip-path: polygon(0px 4px,
          2px 4px,
          2px 2px,
          4px 2px,
          4px 0px,
          calc(100% - 4px) 0px,
          calc(100% - 4px) 2px,
          calc(100% - 2px) 2px,
          calc(100% - 2px) 4px,
          100% 4px,
          100% calc(100% - 4px),
          calc(100% - 2px) calc(100% - 4px),
          calc(100% - 2px) calc(100% - 2px),
          calc(100% - 4px) calc(100% - 2px),
          calc(100% - 4px) 100%,
          4px 100%,
          4px calc(100% - 2px),
          2px calc(100% - 2px),
          2px calc(100% - 4px),
          0px calc(100% - 4px));
      position: absolute;
      top: 5%;
      // width: 99.5%;
      width: 98.5%;
      height: 90%;
      z-index: 3;
      background-color: $cak-black;
      // left: .2%;
      left: .8%;
    }

    span {
      z-index: 10;
      position: relative;
    }

    &::after {
      content: none;
    }
  }
}

.loading-start,
.loading-finish {    

  .loading-items {
    display: flex;
    gap: 1px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    .loading-start-item {
      height: 100%;
      width: calc(100% / 6);
      background-color: $cak-green;
      animation: fadeIn .3s forwards;
      opacity: 0;

      &:nth-child(5),
      &:nth-child(6) {
        background-color: transparent !important;
      }
    }

    .loading-finish-item {
      height: 100%;
      width: calc(100% / 6);
      background-color: $cak-green;
      animation: fadeIn .3s forwards;
    }
  }

  .icon-check {
    z-index: 5;
    position: absolute;
    right: 2.5%;
    height: 100%;
    top: 0;
  }

  span {
    z-index: 5;
  }
}