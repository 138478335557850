:root {
  --toastify-toast-min-height: 45px !important;
}

.Toastify__toast-container {
  // padding: 72px 24px 0 24px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  top: 72px !important;

  .Toastify__toast {
    padding: 12px 1rem !important;
    border-radius: 0 !important;
  }

  .Toastify__toast-body {
    font-family: $font-family-sans-serif !important;
    font-size: $font-size-sm !important;
    margin: 0 !important;
    padding: 0 !important;
    font-weight: 400 !important;
  }

  .Toastify__toast--success {
    background: $cak-green !important;

    .Toastify__toast-body {
      &::before {
        content: url("../../img/icon/misc/toast-success.svg");
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }
    }

    .progress-toast-custom {
      background-color: $cak-green !important;
    }
  }  

  .Toastify__toast--error {
    background-color: $cak-red !important; 

    .Toastify__toast-body {
      &::before {
        content: url("../../img/icon/misc/toast-error.svg");
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }
    }

    .progress-toast-custom {
      background-color: $cak-red !important;
    }
  }

  @include media-breakpoint-up(lg) {
    top: 0 !important;
  }
}