.sc-list-news {
  margin-top: 36px;
  margin-bottom: 38px;
  position: relative;

  .news-item-with-number {
    border-bottom: 1px solid rgba($white, .3);

    .news-item {
      border-bottom: none !important;
    }
  }

  .news-item {
    gap: 24px;
    border-bottom: 1px solid rgba($white, .3);

    &.no-gap {
      gap: 0 !important;
    }

    .news-item-thumbnail {
      width: clamp(74px,20.55vw,74px);
      height: clamp(74px,20.55vw,74px);
    }
  }

  .latest-news-image {
    width: clamp(250px, 69.44vw, 460px);
    height: clamp(200px, 55.55vw, 368px);
  }

  .icon-latest-news {
    position: absolute;
    right: calc(-212px / 2.5);
    top: calc(-112px / 1.3);
  }  

  @include media-breakpoint-up(lg) {
    .latest-news-image {
      width: clamp(250px, 69.44vw, 460px);
      height: clamp(250px, 69.44vw, 460px);
    }
  }
}