// ========================================================================================================================================
// Download - Custom
// ========================================================================================================================================

// For screenshot border radius (if using .png)
.download {
  .cover-body {
    .col-img {
      img {
        max-width: 360px;
        border-radius: 1rem 1rem 0 0;
        @include media-breakpoint-up(md) {
          border-radius: 1.25rem 1.25rem 0 0;
        }
      }
    }
  }
}
