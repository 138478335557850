.sc-investment-list {
  .label {
    @include responsiveToVW(font-size, 14px, 16px);
    margin-bottom: 0.25rem;
    opacity: 0.7;
  }
  .title {
    @include responsiveToVW(font-size, 28px, 32px);
    margin-bottom: 1.5rem;
    font-weight: $font-weight-semibold;
  }
  .list-video {
    display: grid;
    gap: 1.5rem;
    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
  .list-video-desktop {
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
  .list-video,
  .list-video-desktop {
    .video-item {
      position: relative;
      .card-body {
        background-color: $gray-600;
        @include media-breakpoint-up(lg) {
          min-height: 125px;
        }
        .card-title {
          @include responsiveToVW(font-size, 28px, 28px);
          font-weight: $font-weight-semibold;
          color: $white;
          margin-bottom: 0;
          cursor: pointer;
          @include text-truncate-multiline(2);
        }
        .img-play {
          position: absolute;
          top: -2rem;
          cursor: pointer;
        }
      }
    }
  }
}

.sc-investment-skill {
  padding: 3rem 0 2.5rem;
  .title {
    @include responsiveToVW(font-size, 28px, 32px);
    margin-bottom: 1.5rem;
    font-weight: $font-weight-semibold;
    max-width: 312px;
    @include media-breakpoint-up(md) {
      max-width: none;
    }
  }
  .list-skill {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    @include media-breakpoint-up(md) {
      overflow: inherit;
      margin-left: auto;
      margin-right: auto;
      padding-left: 0;
      padding-right: 0;
    }
    .skill-item {
      .card-title {
        @include responsiveToVW(font-size, 20px, 24px);
        @include text-truncate-multiline(2);
        font-weight: $font-weight-semibold;
        margin-bottom: 0.75rem;
      }
      .btn-outline-light {
        .icon-arrow {
          margin-left: 0.5rem;
          filter: invert(0%);
          transition: $transition-base;
        }
        &:hover,
        &:focus {
          .icon-arrow {
            filter: invert(100%);
          }
        }
      }

      @include media-breakpoint-up(lg) {
        &.ml-lg-56px {
          margin-left: 56px !important;
        }
        &.mr-lg-80px {
          margin-right: 56px !important;
        }
      }
    }
  }
}

.sc-investment-detail {
  position: relative;
  .img-misc {
    position: absolute;
    &.misc-outline {
      right: 28px;
      top: 36px;
    }
    &.misc-red {
      right: 0;
      top: 0;
    }
    &.misc-star {
      right: 5px;
      top: 54px;
    }
  }
  .title {
    @include responsiveToVW(font-size, 28px, 32px);
    margin-bottom: 1rem;
    font-weight: $font-weight-semibold;
  }
  .container-video {
    position: relative;
    margin-bottom: 1.5rem;
    padding: 0 !important;
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
    .video-content {
      position: relative;
      margin: auto;
      z-index: 1;
      display: block;
      @include media-breakpoint-up(md) {
        max-width: 300px;
      }
    }
    .img-play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      cursor: pointer;
      z-index: 2;
    }
  }
  .content {
    p {
      @include responsiveToVW(font-size, 14px, 16px);
    }
  }
}
