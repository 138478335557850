.sc-about-header {
  position: relative;
  padding: 1rem 0;
  .img-fluid {
    width: 100%;
  }
  .description {
    @include responsiveToVW(font-size, 14px, 16px);
    margin: 1.5rem 0;
  }
  .content-mobile {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
  .content {
    display: none;
    @include media-breakpoint-up(md) {
      position: relative;
      display: block;
      .img-fluid {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
}

.sc-about-crew {
  position: relative;
  padding: 1rem 0;
  .bg-grid {
    position: absolute;
    width: 195px;
    left: -40px;
    top: -25px;
  }
  .wrapper-title {
    display: grid;
    grid-template-columns: 1fr 144px;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
    .title {
      @include responsiveToVW(font-size, 38px, 40px);
      margin-bottom: 0;
      font-weight: $font-weight-semibold;
      max-width: 178px;
      @include media-breakpoint-up(md) {
        max-width: none;
      }
    }
    .wrapper-lottie {
      @include media-breakpoint-up(md) {
        max-width: 144px;
      }
    }
  }
  .list-crew {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(3, 1fr);
    }
    .crew-item {
      .card-body {
        background-color: $gray-600;
        .wrapper-top-card {
          display: grid;
          grid-template-columns: 1fr 28px 28px;
          gap: 0.5rem;
          align-items: center;
          margin-bottom: 0.25rem;
          .title-card {
            @include responsiveToVW(font-size, 20px, 24px);
            font-weight: $font-weight-semibold;
            margin-bottom: 0;
          }
          .icon-socmed {
            cursor: pointer;
          }
        }
        .subtitle-card {
          @include responsiveToVW(font-size, 14px, 16px);
          margin-bottom: 0;
        }
      }
    }
  }
}
