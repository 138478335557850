// ========================================================================================================================================
// Footer - Custom
// ========================================================================================================================================

.bd-home {
  footer {
    position: sticky;
    bottom: 0;
    z-index: 0;
  }
}

footer {
  position: sticky;
  bottom: 0;
  z-index: 0;

  .footer-main {
    padding: 2.5rem 0;

    h5 {
      @include responsiveToVW(font-size, 14px, 16px);
      opacity: 0.7;
      font-weight: $font-weight-semibold;
    }

    .footer-to-top {
      text-align: center;

      .wrapper-button {
        position: relative;
        width: fit-content;
        margin: auto;

        .img-misc {
          position: absolute;

          &.misc-top {
            right: -14px;
            top: -6px;
          }

          &.misc-bottom {
            bottom: -4px;
            left: -14px;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        text-align: right;
      }
    }

    .footer-social {
      .list-socmed {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
      }
    }

    .footer-address {
      p {
        @include responsiveToVW(font-size, 14px, 16px);
        margin-bottom: 0;
      }
    }

    .footer-tnc-pp {
      .btn-link {
        @include responsiveToVW(font-size, 14px, 16px);
        cursor: pointer;
        font-weight: $font-weight-semibold;
        font-family: $headings-font-family;
        text-transform: uppercase;
      }
    }
  }

  .footer-bottom {
    background-color: $gray-700;
    padding: 12px 0;

    @include media-breakpoint-up(lg) {
      padding: 18px 0;
    }

    .col-left {
      p {
        @include responsiveToVW(font-size, 12px, 14px);
        margin-bottom: 0;
      }
    }

    .col-right {
      display: none;

      @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 2.25rem;

        .btn-link {
          @include responsiveToVW(font-size, 14px, 16px);
          cursor: pointer;
          font-weight: $font-weight-semibold;
          font-family: $headings-font-family;
          text-transform: uppercase;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    border-top: 1px solid rgba($white, .3);
  }
}