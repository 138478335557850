.banner-slider {
  position: relative;

  .grfx {
    position: absolute;
    @include responsiveToVW(left, 24px, 24px);
    top: -1rem;

    .whiteRing {
      position: relative;
      bottom: -1.2rem;
      @include responsiveToVW(margin-right, .3px, .3px);
      margin-left: .3rem;
      @include responsiveToVW(width, 8px, 24px);
      @include responsiveToVW(height, 8px, 24px);
    }

    .blueBall {
      @include responsiveToVW(width, 34px, 45px);
      @include responsiveToVW(height, 34px, 45px);
    }

    .redStar {
      animation: spin 5s linear infinite;
      @include responsiveToVW(width, 27px, 35px);
      @include responsiveToVW(height, 27px, 35px);
    }

    @include media-breakpoint-up(md) {
      width: calc(52.5% - (294px / 2));
      display: flex;
      justify-content: flex-end
    }
  }

  .swiper {
    // @include responsiveToVW(min-height, 465px, 522px);
    height: 465px !important;

    @include media-breakpoint-up(lg) {
      height: 522px !important;
    }
  }

  .swiper-wrapper {
    align-items: center !important;
  }

  .swiper-slide {
    transition: .35s;
    // @include responsiveToVW(width, 238px, 260px);
    // height: clamp(420px, 128.33vw, 462px);

    height: 420px !important;

    @include media-breakpoint-up(lg) {
      height: 462px !important;
    }
  }

  .banner-slide-active {
    // @include responsiveToVW(width, 260px, 294px);
    // height: clamp(462px, 128.33vw, 522px);
    // width: 260px !important;    

    height: 462px !important;

    @include media-breakpoint-up(lg) {
      height: 522px !important;
    }

    .banner-text-container-news {
      .banner-title {
        font-size: clamp(30px, 30px, 30px) !important;
      }
    }

    .news-title {
      h3 {
        font-size: clamp(48px, 8.88vw, 48px) !important;
      }
    }
  }

  .banner-slide-indicator {
    display: flex;
    gap: .5rem;
    justify-content: center;

    .banner-slide-indicator-item {
      width: 8px;
      height: 6px;
      background-color: rgba($white, .3);
      transition: .3s;
      cursor: pointer;

      &.active {
        width: 48px;
        background-color: $cak-orange;
      }
    }
  }

  .banner-inside {
    position: relative;

    .todays-pick-cak-logo {
      background-image: url("../../../assets/img/icon/misc/bg-logo-blue.png");
      background-size: 100% 100%;
      position: absolute;
      top: 0;
      right: 0;
      width: clamp(85px, 85px, 150px);
      height: clamp(61px, 61px, 107px);
      display: flex;
      justify-content: center;
      align-items: center;

      .cak-logo {
        width: 65px;
        height: 38px;
      }
    }

    .banner-text-container {
      padding: .5rem 1rem 1rem 1rem;
      position: absolute;
      bottom: 0;
      width: 100%;
      // clip-path: polygon(0 0, 85% 0%, 85% 28%, 100% 28%, 100% 100%, 0 100%);

      &::before {
        content: "";
        background-color: $cak-yellow;
        position: absolute;
        width: 100%;
        height: 70%;
        z-index: 4;
        left: 0;
        bottom: 0;
        border-top: 1px solid $cak-black;
      }

      &::after {
        content: "";
        background-color: $cak-yellow;
        position: absolute;
        width: 85%;
        height: 30%;
        z-index: 4;
        left: 0;
        top: 2px;
        border-top: 1px solid $cak-black;
        border-right: 1px solid $cak-black;
      }

      .banner-badge-shadow {
        background-color: $cak-black;
        padding: 5px 10px;
        padding-right: 1rem;
        font-size: clamp(12px, 1vw, 14px);
        font-weight: bold;
        position: absolute;
        top: calc(-21.5px);
        left: calc(1rem - 1px);
        z-index: 5;
        clip-path: polygon(0 0, 100% 0, 100% 85%, 90% 85%, 90% 100%, 0 100%);
        font-family: $font-family-serif;
      }

      .banner-badge {
        background-color: $cak-red;
        padding: .25rem .5rem;
        padding-right: 1rem;
        font-size: clamp(12px, 1vw, 14px);
        font-weight: bold;
        position: absolute;
        top: calc(-27px / 1.3);
        left: 1rem;
        z-index: 10;
        clip-path: polygon(0 0, 100% 0, 100% 85%, 90% 85%, 90% 100%, 0 100%);
        font-family: $font-family-serif;
      }

      .banner-title {
        font-size: clamp(26px, 1vw, 32px);
        color: $cak-black;
        width: 85%;
        z-index: 6;
        position: relative;
        font-family: $font-family-serif;
      }

      .btn {
        z-index: 6;
        background-color: $cak-black !important;
        color: $white !important;
        padding: .5rem 1.25rem;
      }
    }

    .banner-text-container-news {
      position: absolute;
      bottom: 0;
      display: flex;
      flex-direction: column;
      padding: 1.75rem 1rem;

      .banner-title {
        font-family: $font-family-serif;
        line-height: 2.55rem;
        font-size: clamp(24px, 24px, 24px);
        margin-bottom: 20px;
      }
    }

    .news-title {
      position: absolute;
      padding: 2.25rem 1rem;
      top: 0;

      h3 {
        font-size: clamp(40px, 8.88vw, 40px);
        line-height: 40px;

        span {
          color: transparent !important;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: $white;
        }
      }
    }
  }
}