// ░█▀▀▄ ░█▀▀▀█ 　 ░█▄─░█ ░█▀▀▀█ ▀▀█▀▀ 　 ░█▀▀▀ ░█▀▀▄ ▀█▀ ▀▀█▀▀
// ░█─░█ ░█──░█ 　 ░█░█░█ ░█──░█ ─░█── 　 ░█▀▀▀ ░█─░█ ░█─ ─░█──
// ░█▄▄▀ ░█▄▄▄█ 　 ░█──▀█ ░█▄▄▄█ ─░█── 　 ░█▄▄▄ ░█▄▄▀ ▄█▄ ─░█──
// Override style on .download-custom.scss instead
// unless you want to upgrade the component version

// ========================================================================================================================================
// Download
// ========================================================================================================================================

.download {
  .cover-body {
    padding-bottom: 0 !important;
  }
  .col-img {
    display: flex;
    justify-content: center;
    @include media-breakpoint-down(sm) {
      padding: 0 2rem;
    }
    @include media-breakpoint-up(md) {
      padding-right: 3rem;
    }
    img {
      width: 100%;
      max-width: 360px;
    }
  }
  .col-text {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    @include media-breakpoint-down(sm) {
      align-items: center;
      text-align: center;
      margin-bottom: 2.5rem;
    }
    @include media-breakpoint-up(md) {
      justify-content: center;
      margin-bottom: 1.25rem;
    }
    .btn-group {
      display: flex;
      a {
        margin-right: 1rem;
        img {
          max-width: 150px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
