.story-custom-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1040;
  background-color: $black;
  display: flex;
  justify-content: center;


  @include media-breakpoint-up(lg) {
    .swiper-story {
      height: 100%;
      .swiper-slide {
        display: flex;
        justify-content: center;
      }
    }
  }  
}

.nav-story {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  z-index: 1000;
  .nav-story-left {
    width: 50%;
    height: 100%;
    background-color: transparent;
    z-index: 1000;
  }
  .nav-story-right {
    width: 50%;
    height: 100%;
    background-color: transparent;
    z-index: 1000;
  }
}