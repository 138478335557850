// Buttons + Forms
// Version 2.0.0
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         .5rem; // Antikode - .6875 for 48px height, .9375rem for 56px height
$input-btn-padding-x:         .375rem; // Antikode
$input-btn-font-family:       null;
$input-btn-font-size:         $font-size-base;
$input-btn-line-height:       $line-height-base;

$input-btn-focus-width:       .1rem; // Antikode
$input-btn-focus-color:       rgba($component-active-bg, 1); // Antikode - default: .25
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color; // Antikode

$input-btn-padding-y-sm:      .25rem;
$input-btn-padding-x-sm:      .5rem;
$input-btn-font-size-sm:      $font-size-sm;
$input-btn-line-height-sm:    $line-height-sm;

$input-btn-padding-y-lg:      .5rem;
$input-btn-padding-x-lg:      1rem;
$input-btn-font-size-lg:      $font-size-lg;
$input-btn-line-height-lg:    $line-height-lg;

$input-btn-border-width:      $border-width;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               $input-btn-padding-y;
$btn-padding-x:               $input-btn-padding-x * 2; // Antikode
$btn-font-family:             $input-btn-font-family;
$btn-font-size:               $input-btn-font-size;
$btn-line-height:             $input-btn-line-height;
$btn-white-space:             null; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm:            $input-btn-padding-y-sm;
$btn-padding-x-sm:            $input-btn-padding-x-sm * 2; // Antikode
$btn-font-size-sm:            $input-btn-font-size-sm;
$btn-line-height-sm:          $input-btn-line-height-sm;

$btn-padding-y-lg:            $input-btn-padding-y-lg;
$btn-padding-x-lg:            $input-btn-padding-x-lg * 2; // Antikode
$btn-font-size-lg:            $input-btn-font-size-lg;
$btn-line-height-lg:          $input-btn-line-height-lg;

$btn-border-width:            $input-btn-border-width;

$btn-font-weight:             $font-weight-normal;
$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075);
$btn-focus-width:             0; // Antikode
$btn-focus-box-shadow:        $input-btn-focus-box-shadow; 
$btn-disabled-opacity:        .65;
$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125);

$btn-link-disabled-color:     $gray-600;

$btn-block-spacing-y:         .5rem;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $border-radius;
$btn-border-radius-lg:        $border-radius-lg;
$btn-border-radius-sm:        $border-radius-sm;

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;


// Forms

$label-margin-bottom:                   .5rem;

$input-padding-y:                       $input-btn-padding-y;
$input-padding-x:                       $input-btn-padding-x;
$input-font-family:                     $input-btn-font-family;
$input-font-size:                       $input-btn-font-size;
$input-font-weight:                     $font-weight-base;
$input-line-height:                     $input-btn-line-height;

$input-padding-y-sm:                    $input-btn-padding-y-sm;
$input-padding-x-sm:                    $input-btn-padding-x-sm;
$input-font-size-sm:                    $input-btn-font-size-sm;
$input-line-height-sm:                  $input-btn-line-height-sm;

$input-padding-y-lg:                    $input-btn-padding-y-lg;
$input-padding-x-lg:                    $input-btn-padding-x-lg;
$input-font-size-lg:                    $input-btn-font-size-lg;
$input-line-height-lg:                  $input-btn-line-height-lg;

$input-bg:                              $white;
$input-disabled-bg:                     $gray-100; // Antikode

$input-color:                           $body-color; // Antikode
$input-border-color:                    $gray-200; // Antikode
$input-border-width:                    $input-btn-border-width;
$input-box-shadow:                      none; // Antikode - default: inset 0 1px 1px rgba($black, .075)

$input-border-radius:                   $border-radius;
$input-border-radius-lg:                $border-radius-lg;
$input-border-radius-sm:                $border-radius-sm;

$input-focus-bg:                        $input-bg;
$input-focus-border-color:              $component-active-bg; // Antikode
$input-focus-color:                     $input-color;
$input-focus-width:                     $input-btn-focus-width;
$input-focus-box-shadow:                $input-btn-focus-box-shadow;

$input-placeholder-color:               $gray-300; // Antikode
$input-plaintext-color:                 $body-color;

$input-height-border:                   $input-border-width * 2;

$input-height-inner:                    add($input-line-height * 1em, $input-padding-y * 2);
$input-height-inner-half:               add($input-line-height * .5em, $input-padding-y);
$input-height-inner-quarter:            add($input-line-height * .25em, $input-padding-y / 2);

$input-height:                          add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false));
$input-height-sm:                       add($input-line-height-sm * 1em, add($input-padding-y-sm * 2, $input-height-border, false));
$input-height-lg:                       add($input-line-height-lg * 1em, add($input-padding-y-lg * 2, $input-height-border, false));

$input-icon-width:                      3rem;

$input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$form-text-margin-top:                  .25rem;

$form-check-input-gutter:               1.25rem;
$form-check-input-margin-y:             .3rem;
$form-check-input-margin-x:             .25rem;

$form-check-inline-margin-x:            .75rem;
$form-check-inline-input-margin-x:      .3125rem;

$form-grid-gutter-width:                10px;
$form-group-margin-bottom:              1rem;

$input-group-addon-color:               $input-color;
$input-group-addon-bg:                  $gray-200;
$input-group-addon-border-color:        $input-border-color;

$custom-forms-transition:               background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$custom-control-gutter:                 .5rem;
$custom-control-spacer-x:               1rem;
$custom-control-cursor:                 null;

$custom-control-indicator-size:         1rem;
$custom-control-indicator-bg:           $input-bg;

$custom-control-indicator-bg-size:      50% 50%;
$custom-control-indicator-box-shadow:   $input-box-shadow;
$custom-control-indicator-border-color: $gray-500;
$custom-control-indicator-border-width: $input-border-width;

$custom-control-label-color:            null;

$custom-control-indicator-disabled-bg:          $input-disabled-bg;
$custom-control-label-disabled-color:           $gray-600;

$custom-control-indicator-checked-color:        $component-active-color;
$custom-control-indicator-checked-bg:           $component-active-bg;
$custom-control-indicator-checked-disabled-bg:  rgba(theme-color("primary"), .5);
$custom-control-indicator-checked-box-shadow:   none;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg;

$custom-control-indicator-focus-box-shadow:     $input-focus-box-shadow;
$custom-control-indicator-focus-border-color:   $input-focus-border-color;

$custom-control-indicator-active-color:         $component-active-color;
$custom-control-indicator-active-bg:            lighten($component-active-bg, 35%);
$custom-control-indicator-active-box-shadow:    none;
$custom-control-indicator-active-border-color:  $custom-control-indicator-active-bg;

$custom-checkbox-indicator-border-radius:       $border-radius;
$custom-checkbox-indicator-icon-checked:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>");

$custom-checkbox-indicator-indeterminate-bg:           $component-active-bg;
$custom-checkbox-indicator-indeterminate-color:        $custom-control-indicator-checked-color;
$custom-checkbox-indicator-icon-indeterminate:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'><path stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/></svg>");
$custom-checkbox-indicator-indeterminate-box-shadow:   none;
$custom-checkbox-indicator-indeterminate-border-color: $custom-checkbox-indicator-indeterminate-bg;

$custom-radio-indicator-border-radius:          50%;
$custom-radio-indicator-icon-checked:           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='#{$custom-control-indicator-checked-color}'/></svg>");

$custom-switch-width:                           $custom-control-indicator-size * 1.75;
$custom-switch-indicator-border-radius:         $custom-control-indicator-size / 2;
$custom-switch-indicator-size:                  subtract($custom-control-indicator-size, $custom-control-indicator-border-width * 4);

$custom-select-padding-y:           $input-btn-padding-y; // Antikode
$custom-select-padding-x:           $input-btn-padding-x; // Antikode
$custom-select-font-family:         $input-font-family;
$custom-select-font-size:           $input-font-size;
$custom-select-height:              $input-height;
$custom-select-indicator-padding:   1rem; // Extra padding to account for the presence of the background-image based indicator
$custom-select-font-weight:         $input-font-weight;
$custom-select-line-height:         $input-line-height;
$custom-select-color:               $input-color;
$custom-select-disabled-color:      $gray-600;
$custom-select-bg:                  $input-bg;
$custom-select-disabled-bg:         $gray-200;
$custom-select-bg-size:             8px 10px; // In pixels because image dimensions
$custom-select-indicator-color:     $gray-800;
$custom-select-indicator:           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>");
$custom-select-background:          escape-svg($custom-select-indicator) no-repeat right $custom-select-padding-x center / $custom-select-bg-size; // Used so we can have multiple background elements (e.g., arrow and feedback icon)

$custom-select-feedback-icon-padding-right: add(1em * .75, (2 * $custom-select-padding-y * .75) + $custom-select-padding-x + $custom-select-indicator-padding);
$custom-select-feedback-icon-position:      center right ($custom-select-padding-x + $custom-select-indicator-padding);
$custom-select-feedback-icon-size:          $input-height-inner-half $input-height-inner-half;

$custom-select-border-width:        $input-border-width;
$custom-select-border-color:        $input-border-color;
$custom-select-border-radius:       $border-radius;
$custom-select-box-shadow:          inset 0 1px 2px rgba($black, .075);

$custom-select-focus-border-color:  $input-focus-border-color;
$custom-select-focus-width:         $input-focus-width;
$custom-select-focus-box-shadow:    0 0 0 $custom-select-focus-width $input-btn-focus-color;

$custom-select-padding-y-sm:        $input-padding-y-sm;
$custom-select-padding-x-sm:        $input-padding-x-sm;
$custom-select-font-size-sm:        $input-font-size-sm;
$custom-select-height-sm:           $input-height-sm;

$custom-select-padding-y-lg:        $input-padding-y-lg;
$custom-select-padding-x-lg:        $input-padding-x-lg;
$custom-select-font-size-lg:        $input-font-size-lg;
$custom-select-height-lg:           $input-height-lg;

$custom-range-track-width:          100%;
$custom-range-track-height:         .5rem;
$custom-range-track-cursor:         pointer;
$custom-range-track-bg:             $gray-300;
$custom-range-track-border-radius:  1rem;
$custom-range-track-box-shadow:     inset 0 .25rem .25rem rgba($black, .1);

$custom-range-thumb-width:                   1rem;
$custom-range-thumb-height:                  $custom-range-thumb-width;
$custom-range-thumb-bg:                      $component-active-bg;
$custom-range-thumb-border:                  0;
$custom-range-thumb-border-radius:           1rem;
$custom-range-thumb-box-shadow:              0 .1rem .25rem rgba($black, .1);
$custom-range-thumb-focus-box-shadow:        0 0 0 1px $body-bg, $input-focus-box-shadow;
$custom-range-thumb-focus-box-shadow-width:  $input-focus-width; // For focus box shadow issue in IE/Edge
$custom-range-thumb-active-bg:               lighten($component-active-bg, 35%);
$custom-range-thumb-disabled-bg:             $gray-500;

$custom-file-height:                $input-height;
$custom-file-height-inner:          $input-height-inner;
$custom-file-focus-border-color:    $input-focus-border-color;
$custom-file-focus-box-shadow:      $input-focus-box-shadow;
$custom-file-disabled-bg:           $input-disabled-bg;

$custom-file-padding-y:             $input-padding-y;
$custom-file-padding-x:             $input-padding-x;
$custom-file-line-height:           $input-line-height;
$custom-file-font-family:           $input-font-family;
$custom-file-font-weight:           $input-font-weight;
$custom-file-color:                 $input-color;
$custom-file-bg:                    $input-bg;
$custom-file-border-width:          $input-border-width;
$custom-file-border-color:          $input-border-color;
$custom-file-border-radius:         $input-border-radius;
$custom-file-box-shadow:            $input-box-shadow;
$custom-file-button-color:          $custom-file-color;
$custom-file-button-bg:             $input-group-addon-bg;
$custom-file-text: (
  en: "Browse"
);


// Form validation

$form-feedback-margin-top:          $form-text-margin-top;
$form-feedback-font-size:           $small-font-size;
$form-feedback-valid-color:         theme-color("success");
$form-feedback-invalid-color:       theme-color("danger");

$form-feedback-icon-valid-color:    $form-feedback-valid-color;
$form-feedback-icon-valid:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");
$form-feedback-icon-invalid-color:  $form-feedback-invalid-color;
$form-feedback-icon-invalid:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>");


$form-validation-states: ();
// stylelint-disable-next-line scss/dollar-variable-default
$form-validation-states: map-merge(
  (
    "valid": (
      "color": $form-feedback-valid-color,
      "icon": $form-feedback-icon-valid
    ),
    "invalid": (
      "color": $form-feedback-invalid-color,
      "icon": $form-feedback-icon-invalid
    ),
  ),
  $form-validation-states
);