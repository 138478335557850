section.referral {
  .referral-title {
    font-size: 38px;
    font-weight: $font-weight-semibold;
    color: $white;
  }

  .referral-body {
    span {
      font-weight: $font-weight-semibold;
    }
  }

  .referral-tabs {
    border-bottom: 1px solid rgba($white, 0.3);
    .referral-tab {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: .5rem;
      padding-bottom: .5rem;
      transition: .3s;     
      border-bottom: 2px solid rgba($cak-yellow, 0);  

      span {
        font-weight: 500;
        color: rgba($white, 0.7); 
        transition: .3s;       
      }

      &.active {
        border-bottom: 2px solid rgba($cak-yellow, 1);
        span {
          color: rgba($white, 1);
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .referral-title {
      font-size: 44px;
    }

    .referral-body {
      font-size: 18px;
    }

    .referral-tabs-desktop {
      .referral-tab-desktop {
        padding-top: 10px;
        padding-bottom: 10px;
        border: 1px solid rgba($white, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          color: rgba($white, 0.7);
          font-weight: 500;
          font-size: 20px;
        }

        &.active {
          background-color: rgba($cak-yellow, 1);
          
          span {
            color: $black;
          }
        }
      }
    }

    .referral-modal-download {
      width: 100%;
      min-height: 100vh;
      background-color: $black;
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1040;

      .referral-modal-download-container {
        background-color: $white;
        padding: 40px 40px 40px 60px;

        .referral-modal-body {
          color: $black;
        }

        .referral-btn-custom {
          background-color: #2C2C2C;
          padding: 10px;

          span {
            color: $white !important;
          }
        }
      }
    }
  }
}