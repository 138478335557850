// Gradients mixins
// Version 1.0.0
//
// Custom mixins for gradients

// Vertical gradient, from bottom to top
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// prettier-ignore
@mixin gradient-y-to-top($start-color: $gray-700, $end-color: $gray-800, $start-percent: 0%, $end-percent: 100%) {
  background-image: linear-gradient(to top, $start-color $start-percent, $end-color $end-percent);
  background-repeat: repeat-x;
}
// prettier-ignore
@mixin gradient-y-to-bottom($start-color: $gray-700, $end-color: $gray-800, $start-percent: 0%, $end-percent: 100%) {
  background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent);
  background-repeat: repeat-x;
}
// prettier-ignore
@mixin gradient-x-to-right($start-color: $gray-700, $end-color: $gray-800, $start-percent: 0%, $end-percent: 100%) {
  background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent);
  background-repeat: repeat-x;
}
// prettier-ignore
@mixin gradient-x-to-left($start-color: $gray-700, $end-color: $gray-800, $start-percent: 0%, $end-percent: 100%) {
  background-image: linear-gradient(to left, $start-color $start-percent, $end-color $end-percent);
  background-repeat: repeat-x;
}
