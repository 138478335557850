// ░█▀▀▄ ░█▀▀▀█ 　 ░█▄─░█ ░█▀▀▀█ ▀▀█▀▀ 　 ░█▀▀▀ ░█▀▀▄ ▀█▀ ▀▀█▀▀
// ░█─░█ ░█──░█ 　 ░█░█░█ ░█──░█ ─░█── 　 ░█▀▀▀ ░█─░█ ░█─ ─░█──
// ░█▄▄▀ ░█▄▄▄█ 　 ░█──▀█ ░█▄▄▄█ ─░█── 　 ░█▄▄▄ ░█▄▄▀ ▄█▄ ─░█──
// Override style on .stats-custom.scss instead
// unless you want to upgrade the component version

// ========================================================================================================================================
// Stats
// ========================================================================================================================================

.stats {
  background: $dark;
  margin-left: -$container-padding-sm-down;
  margin-right: -$container-padding-sm-down;
  padding: 1.25rem $container-padding-sm-down;
  padding-bottom: 0;
  @include media-breakpoint-up(sm) {
    padding: 2rem;
    padding-bottom: (2rem - 1.5rem);
    margin: 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 3rem;
    padding-bottom: (3rem - 1.5rem);
  }
  .stat-item {
    margin-bottom: 1.5rem;
    &.center-down-sm {
      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }
  }
  .stat-number {
    @include font-size($font-size-base * 2);
    display: block;
    color: $white;
    margin-bottom: 0 !important;
  }
  .stat-label {
    color: rgba($white, 0.75);
    line-height: $headings-line-height;
    @include font-size($font-size-base * 0.9);
  }
}
