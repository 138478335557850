// ░█▀▀▄ ░█▀▀▀█ 　 ░█▄─░█ ░█▀▀▀█ ▀▀█▀▀ 　 ░█▀▀▀ ░█▀▀▄ ▀█▀ ▀▀█▀▀
// ░█─░█ ░█──░█ 　 ░█░█░█ ░█──░█ ─░█── 　 ░█▀▀▀ ░█─░█ ░█─ ─░█──
// ░█▄▄▀ ░█▄▄▄█ 　 ░█──▀█ ░█▄▄▄█ ─░█── 　 ░█▄▄▄ ░█▄▄▀ ▄█▄ ─░█──
// Override style on .footer-custom.scss instead
// unless you want to upgrade the component version

// ========================================================================================================================================
// Footer
// ========================================================================================================================================

footer {
  .nav-link {
    display: inline-block;
    @extend .hover-underline;
  }
}

// ==========================================================================
// Footer Main
// ==========================================================================

.footer-main {
  @include media-breakpoint-down(md) {
    .col-footer {
      margin-bottom: 1.5rem;
    }
    .col-footer:last-child {
      margin-bottom: 0;
    }
  }
}
