.pagination-cak {
  list-style: none;
  display: flex;
  width: 100%;
  // gap: 1rem;
  padding: 0;
  justify-content: space-between;
  width: clamp(284px,78.88vw,348px);

  li {
    width: 2rem;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $font-family-serif;
    font-weight: 500;
    color: rgba($white, .7);

    svg {
      width: 2rem;
      height: 2rem;
    }
  }
}

.pagination-cak-active {
  background-color: $cak-blue;
  clip-path: polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%);
  font-weight: $font-weight-bold !important;
  color: $white !important;
}