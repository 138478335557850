main {
  position: relative;
  overflow-x: hidden;
}

// .grfx-top-container {
//   position: absolute;
//   top: 0;
//   width: 100vw;

//   .grfx-top {
//     position: absolute;
//     top: 0;
//     right: calc(-195px / 4);
//     // z-index: 1031;
//   }

//   @include media-breakpoint-up(lg) {
//     // top: -72px;
//   }
// }

.home {
  position: relative;
  z-index: 10;
  background-color: $cak-black;
}

.sc-home-search-banner {
  @include responsiveToVW(padding-top, 8px, 20px);
  position: relative;

  .search-input-home {
    z-index: 1034;
    position: relative;
  }

  @include media-breakpoint-up(md) {
    min-height: calc(100vh - 64px);
    min-height: fit-content;
    padding-top: 0;

    &.iphone-height {
      min-height: calc(100vh - 64px - 59px) !important;
    }
  }
}

.sc-home-daily-insight {
  position: relative;

  .sc-home-daily-insight-inside {
    &::before {
      content: "";
      position: absolute;
      background-image: url("../../img/grfx/grid-bg.svg");
      z-index: 1;
      right: calc(-195px / 6);
      top: calc(-195px / 4);
      width: 195px;
      height: 195px;
    }
  }

  .story-line {
    display: flex;

    .swiper {
      margin: 0 !important;
    }

    .swiper-slide {
      @include responsiveToVW(width, 78px, 106px);
    }

    .story-line-container {
      @include responsiveToVW(width, 78px, 106px);

      .story-line-item {
        @include responsiveToVW(width, 64px, 88px);
        @include responsiveToVW(height, 64px, 88px);
        background: $cak-gradient-yellow;
        border-radius: 50%;
        padding: 2px;

        &.opened {
          background: transparent;
        }

        .story-line-img {
          width: 100%;
          height: 100%;
          background-color: $cak-black;
          padding: 0.35rem;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform: scale(1.3);
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    &::before {
      content: "";
      position: absolute;
      background-image: url("../../img/grfx/grid-bg.svg");
      z-index: 1;
      right: -3rem;
      top: -8rem;
      width: 255px;
      height: 255px;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .sc-home-daily-insight-inside {
      max-width: 100% !important;
      padding-left: calc(60px + 24px);
      min-height: 370px;

      .heading-title {
      }

      .story-line {
      }

      &::before {
        display: none;
      }
    }
  }
}

.sc-home-investment-101 {
  .sc-home-investment-101-container {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      background-image: url("../../img/grfx/grid-bg.svg");
      z-index: 1;
      left: calc(-195px / 6);
      top: 0;
      width: 195px;
      height: 195px;
    }

    .sc-home-investment-101-inside {
      width: clamp(100%, 100%, 392px);
    }

    .swiper-slide {
      width: clamp(144px, 39.16vw, 181px) !important;
      // height: clamp(262px, 72.77vw, 320px) !important;
    }

    .bubble-chat {
      background-image: url("../../img/icon/misc/bubble-chat.svg");
      background-size: 100%;
      background-repeat: no-repeat;
      @include responsiveToVW(width, 176px, 176px);
      @include responsiveToVW(height, 44px, 44px);
      display: flex;
      align-items: center;
      justify-content: center;

      h6 {
        @include responsiveToVW(margin-bottom, 10px, 10px);
        text-transform: uppercase;
        font-weight: $font-weight-semibold;
      }
    }

    .sc-home-investment-101-lamp {
      right: -20px;
      top: 0;
      width: 146px;

      .the-lamp {
        opacity: 0;
      }

      img {
        transform: translateX(20px);
        @include responsiveToVW(width, 111px, 140px);
      }
    }
  }

  .sc-home-investment-101-terms_of_the_day {
    display: flex;
    align-items: center;
    height: clamp(3.75rem, 3.75rem, 4.625rem);
    justify-content: flex-end;
    padding-right: 1.5rem;

    .quotes {
      position: absolute;
      left: 1.5rem;
      width: clamp(44px, 12.2vw, 44px);
      height: clamp(44px, 12.2vw, 44px);
      z-index: 10;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .terms-box {
      justify-self: flex-end;
      background-color: $cak-gray-2;
      width: clamp(calc(100% - 44px), 80.55vw, calc(100% - 22px));
      padding-left: 36px;
      height: 100%;
    }
  }

  .sc-home-investment-101-list_101 {
    .arrow-desktop {
      top: 0;
      right: 20px;
    }

    .swiper-slide {
      display: flex;
    }

    .sc-home-investment-101-list_101-item {
      // height: clamp(262px, 262px, 320px);

      .thumbnail {
        height: clamp(185px, 51.38vw, 233px);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .box-title {
        background-color: $cak-gray-2;
        padding: 20px 12px 16px 12px;
        height: 100%;
        position: relative;

        svg {
          width: clamp(32px, 8.8vw, 40px);
          height: clamp(32px, 8.8vw, 40px);
          top: calc(-32px / 2);
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .sc-home-investment-101-container {
      gap: clamp(0rem, 7rem, 7rem);
      // padding-left: 5rem;

      .bubble-chat {
        position: absolute;
        top: -3rem;
      }

      .sc-home-investment-101-lamp {
        position: absolute !important;
        right: -12rem;
        z-index: 10;
        top: -5rem;
      }
    }

    .sc-home-investment-101-inside {
      justify-content: space-between;
      display: flex;
      flex-direction: column;
    }
  }
}

.modal-terms-container {
  width: 100%;
  overflow-y: auto;
  position: fixed;
  top: 0;
  height: 100vh;
  z-index: 1036;

  .bottom-info {
    background-color: $cak-gray-2;
    position: fixed;
    bottom: 0;

    .icon-close {
      position: absolute;
      right: 24px;
      bottom: 1rem;
      width: 64px;
      height: 64px;

      svg {
        width: 64px;
        height: 64px;
      }
    }
  }

  .quotes-grfx {
    position: absolute;
    top: 0;
    right: 0;
  }

  .img-misc {
    position: absolute;
    right: 0;
    top: 22%;
    z-index: 1;
  }
}

.sc-browse-by-topics {
  background-image: url("../../img/grfx/grid-bg-big.png");
  background-size: cover;

  .sc-browse-by-topics-list {
    gap: clamp(12px, 3.33vw, 20px);
    flex-wrap: wrap;

    .sc-browse-by-topics-list_item {
      border-radius: clamp(27px, 27px, 39px);
      background-color: $cak-gray-1;
      border: 1px solid rgba($white, 0.3);
      padding-top: clamp(10px, 2.7vw, 12px);
      padding-bottom: clamp(10px, 2.7vw, 12px);
      padding-left: clamp(1rem, 4.44vw, 24px);
      padding-right: clamp(1rem, 4.44vw, 24px);
      color: $white;
      font-weight: 500;
      font-size: clamp(16px, 4.44vw, 32px);
    }
  }

  @include media-breakpoint-up(md) {
    background-image: url("../../img/grfx/grid-bg-big-desktop.png");
  }
}

.sc-home-latest-news {
  .icon-latest-news {
    position: absolute;
    right: -80px;
    top: calc(-112px / 1.3);
  }

  .latest-news-image {
    width: clamp(250px, 69.44vw, 460px);
    height: clamp(250px, 69.44vw, 460px);
  }

  .news-item {
    gap: 24px;
    border-bottom: 1px solid rgba($white, 0.3);
  }

  @include media-breakpoint-up(lg) {
    margin-top: 70px;
    margin-bottom: 80px;
  }
}

.sc-home-subscription {
  // background-image: url("../../img/grfx/grid-bg-big-desktop.png");
  // background-size: cover;
  // padding-top: 62px;
  // padding-bottom: 30px;
  padding-top: 3rem;

  @include media-breakpoint-up(lg) {
    padding-bottom: 3rem;
  }
}

.sc-Homeinvestment101Modal {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $cak-black;
  z-index: 2000;
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: 3rem;

  .icon-close {
    position: fixed;
    right: 24px;
    bottom: 1rem;
    width: 64px;
    height: 64px;
    z-index: 1040;

    svg {
      width: 64px;
      height: 64px;
    }
  }
}
