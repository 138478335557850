// Animations base
// Version 2.1.3
//
// Antikode custom animations

.animated {
  animation-duration: $anim-duration;
  animation-fill-mode: both;
}

.infinite {
  animation-iteration-count: infinite;
}

.is-in-view {
  opacity: 1;
}

// Scroll In Animations
$animations-in: "fadeIn", "fadeInUp", "fadeInRight", "fadeInDown", "fadeInLeft",
  "slideInUp", "slideInRight", "slideInDown", "slideInLeft", "zoomIn",
  "zoomInSm", "revealInUp", "revealInRight", "revealInDown", "revealInLeft",
  "revealBlockInUp", "revealBlockInRight", "revealBlockInDown",
  "revealBlockInLeft";

@each $animation in $animations-in {
  .scroll-#{$animation} {
    opacity: 0;
  }

  .#{$animation} {
    opacity: 1;
    animation-duration: $anim-duration;
    animation-fill-mode: both;
  }
}

// Scroll Out Animations
$animations-out: "fadeOut", "fadeOutUp", "fadeOutRight", "fadeOutDown",
  "fadeOutLeft", "slideOutUp", "slideOutRight", "slideOutDown", "slideOutLeft",
  "zoomOut", "zoomOutSm";

@each $animation in $animations-out {
  .scroll-#{$animation} {
    opacity: 1;
  }

  .#{$animation} {
    opacity: 0;
    animation-duration: $anim-duration;
    animation-fill-mode: both;
  }
}

// Delays
@for $i from 1 through 40 {
  .d#{$i},
  .delayp#{$i} {
    animation-delay: 0s + $i * 0.1;
    transition-delay: 0s + $i * 0.1;
  }
}

// ==========================================================================
// Basic Animations
// ==========================================================================

// fadeIn, fadeInUp, fadeInRight, fadeInDown, fadeInLeft
// fadeOut, fadeOutUp, fadeOutRight, fadeOutDown, fadeOutLeft
// slideInUp, slideInRight, slideInDown, slideInLeft
// slideOutUp, slideOutRight, slideOutDown, slideOutLeft
// revealInUp, revealInRight
// revealBlockInUp, revealBlockInRight

//
// Fade
//

// prettier-ignore
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

// prettier-ignore
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

// prettier-ignore
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

// prettier-ignore
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

// prettier-ignore
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(10px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

// prettier-ignore
@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

// prettier-ignore
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translateY(10px);
  }
}

.fadeOutUp {
  animation-name: fadeOutUp;
}

// prettier-ignore
@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translateX(10px);
  }
}

.fadeOutRight {
  animation-name: fadeOutRight;
}

// prettier-ignore
@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.fadeOutDown {
  animation-name: fadeOutDown;
}

// prettier-ignore
@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translateX(-10px);
  }
}

.fadeOutLeft {
  animation-name: fadeOutLeft;
}

//
// Slide
//

// prettier-ignore
@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  animation-name: slideInUp;
}

// prettier-ignore
@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

// prettier-ignore
@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  animation-name: slideInDown;
}

// prettier-ignore
@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

// prettier-ignore
@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

.slideOutUp {
  animation-name: slideOutUp;
}

// prettier-ignore
@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

.slideOutRight {
  animation-name: slideOutRight;
}

// prettier-ignore
@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

.slideOutDown {
  animation-name: slideOutDown;
}

// prettier-ignore
@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

.slideOutLeft {
  animation-name: slideOutLeft;
}

//
// Zoom
//

// prettier-ignore
@keyframes zoomIn {
  from {
    transform: scale(1.6);
  }

  to {
    transform: scale(1);
  }
}

.zoomIn {
  animation-name: zoomIn;
}

// prettier-ignore
@keyframes zoomOut {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.6);
  }
}

.zoomOutSm {
  animation-name: zoomOut;
}

// prettier-ignore
@keyframes zoomInSm {
  from {
    transform: scale(1.2);
  }

  to {
    transform: scale(1);
  }
}

.zoomInSm {
  animation-name: zoomInSm;
}

// prettier-ignore
@keyframes zoomOutSm {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
}

.zoomOutSm {
  animation-name: zoomOutSm;
}

//
// Flash
//

// prettier-ignore
@keyframes flash {

  from,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

.flash {
  animation-name: flash;
}

//
// Pulse
//

// prettier-ignore
@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  animation-name: pulse;
}

//
// Shake
//

// prettier-ignore
@keyframes shakeX {

  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}

.shakeX {
  animation-name: shakeX;
}

// prettier-ignore
@keyframes shakeY {

  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(0, -10px, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(0, 10px, 0);
  }
}

.shakeY {
  animation-name: shakeY;
}

// prettier-ignore
@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px) rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px) rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px) rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px) rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

.headShake {
  animation-name: headShake;
}

//
// Spin
//

// prettier-ignore
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.spin {
  animation: spin 2s infinite linear;  
}

//
// Reveal
//

// prettier-ignore
@keyframes revealInUp {
  0% {
    clip-path: inset(100% 0% 0% 0%);
  }

  100% {
    clip-path: inset(0% 0% 0% 0%);
  }
}

// prettier-ignore
@keyframes revealInRight {
  0% {
    clip-path: inset(0% 100% 0% 0%);
  }

  100% {
    clip-path: inset(0% 0% 0% 0%);
  }
}

// prettier-ignore
@keyframes revealInDown {
  0% {
    clip-path: inset(0% 0% 100% 0%);
  }

  100% {
    clip-path: inset(0% 0% 0% 0%);
  }
}

// prettier-ignore
@keyframes revealInLeft {
  0% {
    clip-path: inset(0% 0% 0% 100%);
  }

  100% {
    clip-path: inset(0% 0% 0% 0%);
  }
}

$animation-directions: "Up", "Right", "Down", "Left";

@each $animation-direction in $animation-directions {
  .revealIn#{$animation-direction}:not(.card) {
    animation: revealIn#{$animation-direction};
    animation-duration: $reveal-anim-duration;
    animation-timing-function: $reveal-ease;
    animation-fill-mode: forwards;

    img {
      animation-name: zoomIn;
      animation-duration: $reveal-img-anim-duration;
      animation-delay: -#{$reveal-img-anim-delay};
      animation-timing-function: $reveal-ease;
      animation-fill-mode: forwards;
    }
  }
}

@each $animation-direction in $animation-directions {
  .revealIn#{$animation-direction}.card {
    .card-bg {
      animation: revealIn#{$animation-direction};
      animation-duration: $reveal-anim-duration;
      animation-timing-function: $reveal-ease;
      animation-fill-mode: forwards;

      img {
        animation-name: zoomIn;
        animation-duration: $reveal-img-anim-duration;
        animation-delay: -#{$reveal-img-anim-delay};
        animation-timing-function: $reveal-ease;
        animation-fill-mode: forwards;
      }
    }
  }
}

@each $animation-direction in $animation-directions {
  .revealBlockIn#{$animation-direction}:not(.card) {
    animation-name: revealIn#{$animation-direction};
    animation-duration: $reveal-anim-duration;
    animation-timing-function: $reveal-ease;
    animation-fill-mode: forwards;

    &::after {
      top: 0;
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background: $reveal-block-bg;
      animation-name: slideOut#{$animation-direction};
      animation-duration: $reveal-anim-duration;
      animation-delay: $reveal-anim-duration;
      animation-timing-function: $reveal-ease;
      animation-fill-mode: forwards;
    }

    img {
      animation-name: zoomIn;
      animation-duration: $reveal-img-anim-duration;
      animation-delay: $reveal-anim-duration - $reveal-img-anim-delay;
      animation-timing-function: $reveal-ease;
      animation-fill-mode: forwards;
    }
  }
}

@each $animation-direction in $animation-directions {
  .revealBlockIn#{$animation-direction} {
    .card-bg {
      position: relative;
      animation-name: revealIn#{$animation-direction};
      animation-duration: $reveal-anim-duration;
      animation-timing-function: $reveal-ease;
      animation-fill-mode: forwards;

      &::after {
        top: 0;
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background: $reveal-block-bg;
        animation-name: slideOut#{$animation-direction};
        animation-duration: $reveal-anim-duration;
        animation-delay: $reveal-anim-duration;
        animation-timing-function: $reveal-ease;
        animation-fill-mode: forwards;
      }

      img {
        animation-name: zoomIn;
        animation-duration: $reveal-img-anim-duration;
        animation-delay: $reveal-anim-duration - $reveal-img-anim-delay;
        animation-timing-function: $reveal-ease;
        animation-fill-mode: forwards;
      }
    }
  }
}

//
// Other Animations
//

// Floating infinite animation
// Normall used with "animation: float 7.5s infinite ease-in-out; position: absolute;"
@keyframes float {
  0% {
    transform: translate3d(0, 5%, 0);
  }

  50% {
    transform: translate3d(0, -5%, 0);
  }

  100% {
    transform: translate3d(0, 5%, 0);
  }
}

//
// Hover Animations
//

// Underline animation on hover
// Normally works for .btn-link, .nav-link
@mixin hover-underline {
  position: relative;
  transition: $transition-base;

  &:before {
    bottom: 0.25rem;
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $primary;
    z-index: 2;
    will-change: transform, color;
    transform: scale3d(0, 1, 1);
    transition: transform 0.35s;
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transform-origin: 100% 50%;
  }

  @include hover {
    color: $primary;

    &:before {
      transform: scale3d(1, 1, 1);
      transform-origin: 0% 50%;
      transition-timing-function: ease;
    }
  }

  &.text-white {
    &:before {
      background-color: $white;
    }
  }

  &.inverse {
    &:before {
      transform: scale3d(1, 1, 1);
      transform-origin: 0% 100%;
    }

    @include hover {
      &:before {
        transform: scale3d(0, 1, 1);
        transform-origin: 100% 0%;
      }
    }
  }
}

.hover-underline {
  @include hover-underline;
}

// Block swipe animation on hover
// for .btn-$color
@mixin hover-blockRight {
  position: relative;
  transition: $transition-base;

  span {
    position: relative;
    z-index: 2;
  }

  .el-block {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    will-change: transform, background-color;
    transform: scaleX(0);
    transform-origin: 100% 50%;
    transition: transform 0.35s;
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  @include hover {
    .el-block {
      transform: scaleX(1);
      transform-origin: 0 50%;
      transition-timing-function: ease;
    }
  }

  @each $color, $value in $theme-colors {
    &.btn-outline-#{$color} {
      .el-block {
        background: darken($value, $btn-hover-bg-darken);
        width: calc(100% + 1px);
      }

      @include hover {
        background: transparent;
        border-color: darken($value, $btn-hover-bg-darken);
      }
    }

    &.btn-#{$color} {
      background: $value;

      .el-block {
        background: darken($value, $btn-hover-bg-darken);
      }

      @include hover {
        border-color: transparent;
      }
    }
  }
}

.hover-blockRight {
  @include hover-blockRight;
}

// Zoom hover effect
// for Card / Image component
.hover-zoomIn {
  .card-bg,
  .inner-content {
    transition: $transition-base;
    transition-duration: 0.4s;
  }

  @include hover {
    .card-bg,
    .inner-content {
      transform: scale(1.06);
    }
  }

  // prettier-ignore
  @include media-breakpoint-down(sm) {

    .card-bg,
    .inner-content {
      transition: none;
      transform: none;
    }

    @include hover {

      .card-bg,
      .inner-content {
        transform: none;
      }
    }
  }
}

.hover-zoomOut {
  .card-bg,
  .inner-content {
    transition: $transition-base;
    transition-duration: 0.4s;
    transform: scale(1.06);
  }

  @include hover {
    .card-bg,
    .inner-content {
      transform: scale(1);
    }
  }

  // prettier-ignore
  @include media-breakpoint-down(sm) {

    .card-bg,
    .inner-content {
      transition: none;
      transform: none;
    }

    @include hover {

      .card-bg,
      .inner-content {
        transform: none;
      }
    }
  }
}

@keyframes shake {
  0% {
    transform: rotate(-20deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(20deg);
  }
}

@keyframes leftToRight {
  0% {
    transform: translate3d(15px, 0, 0);
  }

  50% {
    transform: translate3d(0px, 0, 1);
  }

  100% {
    transform: translate3d(-15px, 0, 0);
  }
}

@keyframes blinking {
  0% {
    opacity: 0;
  }

  26%,
  29%,
  34% {
    opacity: 0.6;
  }

  50%,
  55%,
  56% {
    opacity: 0.3;
  }

  60%,
  70%,
  79% {
    opacity: 1;
  }

  65%,
  67%,
  76%,
  78% {
    opacity: 0.2;
  }

  66%,
  69%,
  77% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

.blink-fast {
  animation: blinking 2s 0.3s linear forwards;
}

.delay-0 {
  transition-delay: 0s !important;
}
