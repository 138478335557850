.sc-404 {
  height: calc(100vh - #{$navbar-height-mobile});
  @include media-breakpoint-up(md) {
    height: calc(100vh - #{$navbar-height-desktop});
  }
  display: flex;
  align-items: center;
  .col-img {
    .img-ufo {
      margin-bottom: 2rem;
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }
  }
  .col-text {
    text-align: center;
    @include media-breakpoint-up(md) {
      text-align: left;
    }
    .title {
      font-weight: $font-weight-semibold;
      @include responsiveToVW(font-size, 32px, 36px);
      margin-top: 2rem;
      margin-bottom: 0.75rem;
    }
    .subtitle {
      @include responsiveToVW(font-size, 14px, 16px);
      margin-bottom: 2rem;
    }
  }
}
