.sc-news-detail {
  position: relative;

  .img-misc {
    position: absolute;

    &.misc-outline {
      top: 50px;
      right: 36px;

      @include media-breakpoint-up(md) {
        top: 79px;
        top: 72px;
        transform: scale(2);
      }
    }

    &.misc-red {
      right: 0;
      top: 15px;

      @include media-breakpoint-up(md) {
        transform: scale(2);
      }
    }
  }

  .sc-news-detail-header-thumbnail {
    padding-top: 4px;

    .sc-news-detail-header-thumbnail-tags {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;

      .sc-news-detail-header-thumbnail-tag {
        background-color: rgba($white, 0.1);
        border: 1px solid rgba($white, 0.3);
        font-family: $font-family-serif;
        padding: 6px 12px;
        border-radius: 1rem;
      }
    }

    @include media-breakpoint-up(lg) {
      padding-top: 2.5rem;
    }
  }

  .subscription-container {
    transform: translateY(-17%);
  }

  .subscription-detail-body {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding-bottom: 0 !important;
  }

  .sc-news-detail-body {
    position: relative;

      a {
        word-wrap: break-word !important;
      }
    

    &.exclusive {
      position: relative;
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100px;
        background: linear-gradient(0deg, rgba(8, 8, 8, 1) 0%, rgba(255, 255, 255, 0) 100%);
        // background: linear-gradient(0deg, rgb(255, 0, 0) 0%, rgba(255, 255, 255, 0) 100%);
        content: "";
        display: block;
      }
    }

    img {
      width: 100%;
    }
  }

  .sc-news-detail-source {
    .sc-news-detail-source-swiper {
      .swiper {
        @include responsiveToVW(padding-right, 40px, 0px);
      }

      .swiper-slide {
        width: clamp(134px, 37.22vw, 389px);
        padding-left: clamp(35px, 7.77vw, 35px);
      }

      .sc-news-detail-source-item {
        width: clamp(134px, 37.22vw, 389px);
        background-color: $cak-gray-2;
        padding: 10px 12px;
        clip-path: polygon(0% 0%,
            90% 0%,
            90% 2%,
            90% 2%,
            92% 2%,
            92% 4%,
            94% 4%,
            94% 6%,
            96% 6%,
            96% 8%,
            98% 8%,
            98% 10%,
            100% 10%,
            100% 100%,
            0% 100%,
            10% 100%,
            10% 98%,
            8% 98%,
            8% 96%,
            6% 96%,
            6% 94%,
            4% 94%,
            4% 92%,
            2% 92%,
            2% 90%,
            0% 90%,
            0% 0%);

        .btn-outline-light {
          &::before {
            background-color: $cak-gray-2 !important;
            width: 98%;
            left: 1%;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .sc-news-detail-source {
      margin-top: 120px;

      .sc-news-detail-source-swiper {
        .sc-news-detail-source-item {
          padding: 24px;
          background-color: $cak-gray-2;

          img {
            width: 50px;
          }
        }
      }
    }
  }
}

.sc-news-detail-like_share_bar {
  position: fixed;
  bottom: 0;
  z-index: 50;
  width: 100%;

  .share-bar-mobile {
    background-color: $cak-gray-2;
    border: 1px solid $cak-black;
    display: flex;
    gap: 1.25rem;
    width: 100%;
    padding: 1rem 1.5rem;
  }

  .share-bar-desktop {
    background-color: $cak-gray-2;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba($white, 0.3);
    padding: 1.75rem 0.5rem;
    border-radius: 5rem;
    flex-direction: column;
    align-items: center;

    hr {
      width: 100%;
      border-bottom: 1px solid rgba($white, 0.3);
    }
  }

  @include media-breakpoint-up(lg) {
    right: 100px;
    top: calc(72px + 120px + 45px + 24px);
    width: 47px;
  }
}

.raining-card {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  left: 0;
  gap: .25rem;
  animation: raining 3s linear forwards;
  transform: translateY(-100vh);
  z-index: 4000;

  .the-heart {
    width: 44px;
    height: 44px;

    img {
      width: 44px;
      height: 44px;
    }
  }
}

@keyframes raining {
  from {
    opacity: 0;
    transform: translateY(-100vh);
  }

  10% {
    opacity: 1;
  }

  to {
    opacity: 1;
    transform: translateY(100vh);
  }
}