// ░█▀▀▄ ░█▀▀▀█ 　 ░█▄─░█ ░█▀▀▀█ ▀▀█▀▀ 　 ░█▀▀▀ ░█▀▀▄ ▀█▀ ▀▀█▀▀
// ░█─░█ ░█──░█ 　 ░█░█░█ ░█──░█ ─░█── 　 ░█▀▀▀ ░█─░█ ░█─ ─░█──
// ░█▄▄▀ ░█▄▄▄█ 　 ░█──▀█ ░█▄▄▄█ ─░█── 　 ░█▄▄▄ ░█▄▄▀ ▄█▄ ─░█──
// Override style on .nav-bottom-custom.scss instead
// unless you want to upgrade the component version

// ========================================================================================================================================
// Nav Bottom
// ========================================================================================================================================

.nav-bottom {
  .nav-bottom-item {
    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      @include media-breakpoint-up(md) {
        padding-left: 3.5rem;
        padding-right: 3.5rem;
      }
      .card-label {
        color: $white;
        font-size: $font-size-sm;
        text-transform: uppercase;
        margin-bottom: 0.5rem;
        display: inline-block;
        position: relative;
      }
      .card-title {
        @include font-size(1.5rem);
      }
    }
    &.prev {
      .card-body {
        align-items: flex-end;
        .card-label {
          &:before {
            font-family: "Anti Icons Light";
            content: var($ai-arrow-left);
            position: absolute;
            left: -1.5rem;
            top: 2px;
          }
        }
      }
    }
    &.next {
      .card-body {
        align-items: flex-start;
        .card-label {
          &:after {
            font-family: "Anti Icons Light";
            content: var($ai-arrow-right);
            position: absolute;
            right: -1.5rem;
            top: 2px;
          }
        }
      }
    }
  }
}
