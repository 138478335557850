// Text utilities
// Version 1.0.0
//
// Custom styles for text utilities.

// Custom text style
.text-uppercase-spaced {
  text-transform: uppercase;
  letter-spacing: 2px;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-underline {
  text-decoration: underline;
}

// Text truncate multiline
.text-truncate-twoline {
  @include text-truncate-multiline(2);
}

.text-truncate-threeline {
  @include text-truncate-multiline(3);
}

.text-cak-orange {
  color: $cak-orange !important;
}

.text-cak-yellow {
  color: $cak-yellow !important;

  span {
    color: $cak-yellow !important;
  }
}

.text-cak-black {
  color: $cak-black !important;
}

.text-cak-blue {
  color: $cak-blue !important;
}

.text-cak-green {
  color: $cak-green !important;
}

.text-cak-red {
  color: $cak-red !important;
}

.text-cak-white-70 {
  color: rgba($white, .7) !important;
}

.font-semibold {
  font-weight: $font-weight-semibold;
}

.font-bold {
  font-weight: $font-weight-bold;
}

.font-weight-500 {
  font-weight: 500;
}

.font-normal {
  font-weight: $font-weight-normal;
}

.font-chakrapetch {
  font-family: $font-family-serif;
}

@function rspw($value) {
  $screen: 360px; //artboard width size on VD
  @return ($value / $screen) * 100vw;
}

@function rspwTablet($value) {
  $screen: 768px; //artboard width size on VD
  @return ($value / $screen) * 100vw;
}

@mixin responsiveToVW($prop, $valsize, $valdesk) {
  #{$prop}: rspw($valsize) !important;

  @include media-breakpoint-up(md) {
    #{$prop}: rspwTablet($valsize) !important;
  }

  @include media-breakpoint-up(lg) {
    #{$prop}: $valdesk !important;
  }
}

.no-overlap {
  padding-top: 64px !important;

  @include media-breakpoint-up(md) {
    padding-top: 72px !important;
  }
}


.text-10 {
  @include responsiveToVW(font-size, 10px, 12px);
}

.text-12 {
  @include responsiveToVW(font-size, 12px, 14px);
}

.text-12-16 {
  @include responsiveToVW(font-size, 12px, 16px);
}

.text-14 {
  @include responsiveToVW(font-size, 14px, 16px);
}

.text-14-18 {
  @include responsiveToVW(font-size, 14px, 18px);
}

.text-14-24 {
  @include responsiveToVW(font-size, 14px, 24px);
}

.text-16 {
  @include responsiveToVW(font-size, 16px, 18px);
}

.text-18 {
  @include responsiveToVW(font-size, 18px, 20px);
}

.text-20 {
  @include responsiveToVW(font-size, 20px, 20px);
}

.text-28 {
  @include responsiveToVW(font-size, 20px, 28px);
}

.text-24 {
  @include responsiveToVW(font-size, 24px, 28px);
}

.text-24-38 {
  @include responsiveToVW(font-size, 24px, 38px);
}

.text-24-44 {
  @include responsiveToVW(font-size, 24px, 44px);
}

.text-28-44 {
  @include responsiveToVW(font-size, 28px, 44px);
}

.text-30-36 {
  @include responsiveToVW(font-size, 30px, 36px);
}

.text-32 {
  @include responsiveToVW(font-size, 32px, 40px);
}

.text-32-44 {
  @include responsiveToVW(font-size, 32px, 44px);
}

.text-32-48 {
  @include responsiveToVW(font-size, 32px, 48px);
}

.text-36 {
  @include responsiveToVW(font-size, 36px, 40px);
}

.text-36-60 {
  @include responsiveToVW(font-size, 36px, 60px);
}

.text-38-44 {
  @include responsiveToVW(font-size, 38px, 44px);
  // font-size: clamp(38px, 1.9vw, 60px);
}

.text-38-60 {
  @include responsiveToVW(font-size, 38px, 60px);
  // font-size: clamp(38px, 1.9vw, 60px);
}

.text-40-60 {
  font-size: clamp(40px, 11.11vw, 60px);
}

.text-64 {
  font-size: clamp(64px, 17.77vw, 64px);
}

.pointer {
  cursor: pointer;
  transition: .3s;

  &:active {
    opacity: .5;
  }

  @include media-breakpoint-up(lg) {
    &:hover {
      opacity: .5;
    }
  }
}

.hover {
  transition: .3s;

  &:hover {
    cursor: pointer;
  }

  @include media-breakpoint-up(lg) {
    &:hover {
      opacity: .7;
    }
  }
}

.text-outline {
  color: transparent !important;
  -webkit-text-stroke-width: .5px;
  -webkit-text-stroke-color: $white;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.transform-y-min75 {
  @include media-breakpoint-up(lg) {
    transform: translateY(-75px);
  }
}

.bg-grey {
  background-color: #434343;
}

.hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}