// ========================================================================================================================================
// Breadcrumb - Custom
// ========================================================================================================================================

// Add custom style here
.breadcrumb {
  .breadcrumb-item {
    @include responsiveToVW(font-size, 12px, 14px);
    display: inline-flex;

    &:not(:last-child) {
      opacity: 0.7;

      &>div {
        @include hover-underline;
        @extend .text-white;
        cursor: pointer;

        &::before {
          bottom: 0;
        }
      }
    }

    &:nth-last-child(-n + 1) {
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
    }
  }

  @include media-breakpoint-up(lg) {
    .breadcrumb-item {
      &:nth-last-child(-n + 1) {
        width: fit-content;
        text-overflow: ellipsis;
        white-space: normal;
        display: inline-block;
      }
    }
  }
}