.sc-news-analysis-category-bar {
  border-bottom: 1px solid rgba($white, 0.3);
  position: sticky;
  top: 4rem;
  z-index: 3;
  background-color: $cak-black;

  .sc-news-analysis-category-bar-container {
    margin-left: -1.5rem;
    margin-right: -1.5rem;

    .swiper {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    .sc-news-analysis-category-bar-container-item {
      h5 {
        color: rgba($white, 0.7);
        padding-top: 13px;
        padding-bottom: 13px;
      }

      hr {
        width: 0;
        height: 2px;
        background-color: $cak-yellow;
        transition: 0.3s;
        // margin-top: .5rem;
      }
    }
  }

  .sc-news-analysis-category-bar-container-arrow {
    border: 1px solid rgba($white, 0.3);
    border-right: none;
    border-bottom: none;
    // height: clamp(47.8px,47.8px,47.8px);
    // width: clamp(60px,60px,60px);
    height: 100%;
    position: absolute;
    right: 0;
    top: 0pt;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $cak-black;
    z-index: 10;
    aspect-ratio: 1 / 1;

    @include media-breakpoint-up(lg) {
      aspect-ratio: unset;
      justify-content: flex-start;
      padding-left: 1rem;
      padding-right: clamp(28px, 7.77vw, 106px);
    }

    .topic-icon-arrow {
      transition: 0.3s;

      &.open {
        transform: rotate(180deg);
      }
    }

    &::before {
      content: "";
      position: absolute;
      width: 35px;
      height: 100%;
      left: -36px;

      background: linear-gradient(270deg, #080808 0%, rgba(8, 8, 8, 0) 100%);
    }
  }

  .sc-news-analysis-category-bar-container-all {
    position: absolute;
    left: 0;
    top: 49.8px;
    z-index: 5;
    right: 0;
    height: 0;
    background-color: $cak-gray-1;
    transition: 0.3s;
    opacity: 0;
    display: flex;
    // column-gap: 12px;
    // row-gap: 16px;
    align-items: flex-start;
    padding: 24px;
    overflow: hidden;

    &.open {
      // height: clamp(100%, 100%, 256px);
      height: auto !important;
      opacity: 1;
      overflow-y: auto;
    }

    .category-items {
      row-gap: 1rem;
      columns: 12px;

      .category-item {
        border-radius: 27px;
        border: 1px solid rgba($white, 0.3);
        color: $white;
        padding: 10px 1rem;
        position: relative;
        z-index: 1;
      }      
    }    
  }  
}

.category-item-overlay {
  content: "";
  position: absolute;
  // top: calc(64px + 48px + 50px);
  top:0;
  height: 100%;
  left: 0;
  right: 0;
  background-color: rgba($cak-black, 0.7);
  z-index: 2;

  &.hidden {
    display: none;
    visibility: hidden;
  }
}

.sc-news-analysis {
  .discover-list-container {
    width: auto;

    .swiper {
      margin-left: -1.25rem;
      margin-right: -1.25rem;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }

    .swiper-slide {
      width: clamp(148px, 148px, 148px);
      height: clamp(262px, 262px, 262px);
    }

    .discover-item {
      // width: clamp(148px, 148px, 148px);
      height: clamp(262px, 262px, 262px);
      position: relative;

      .discover-item-wording {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1rem 0.5rem;
        z-index: 5;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          background-color: rgba($cak-black, 0.5);
        }

        h3 {
          font-size: clamp(20px, 6.66vw, 24px);
          z-index: 1;
          line-height: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        p {
          z-index: 1;
          line-height: 18px;
          font-size: $font-size-sm;
          font-weight: $font-weight-semibold;
        }
      }

      img {
        top: 0;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .discover-list-container {
      width: 100%;

      .swiper {
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
        padding-right: 0;
      }

      .swiper-slide {
        width: auto;
        height: clamp(503px, 503px, 503px);
      }

      .discover-item {
        width: auto;
        height: clamp(503px, 503px, 503px);

        .discover-item-wording {
          padding: 1.5rem 1rem;

          h3 {
            font-size: clamp(52px, 52px, 52px);
            line-height: 40px;
          }

          p {
            font-size: 1.5rem !important;
            line-height: 32px !important;
          }
        }
      }
    }
  }
}

.sc-news_analysis_by_topic {
  .sc-news_analysis_by_topic-top-news {
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
      margin-bottom: 50px;
    }
  }
}

.sc-news-analysis-stock-pick {
  padding: 52px 0;
  background: linear-gradient(180deg,
      rgba(230, 230, 230, 0) 0%,
      rgba(67, 67, 67, 0.6) 30%,
      rgba(67, 67, 67, 0.6) 100%);

  .sc-news-analysis-stock-pick-container {
    position: relative;
    overflow: hidden;
    &::before {
      content: "";
      background-image: url("../../img/grfx/grid-bg.svg");
      position: absolute;      
      top: -50px;
      right: -50px;
      width: 200px;
      height: 200px;
    }
  }

  .stock-pick-list {
    .stock-pick-item {
      background-color: $cak-black;
      clip-path: polygon(0 0, 90% 0, 100% 19%, 100% 100%, 0 100%);
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 34px;
        height: 34px;
        background-color: $cak-red;
        top: 0;
        right: 0;
      }

      &.up {
        &::before {
          background-color: $cak-green !important;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .stock-pick-list {
      .stock-pick-item {
        clip-path: polygon(0 0, 83% 0, 100% 13%, 100% 100%, 0 100%);
        padding: 2rem 24px !important;

        &::before {
          width: 52px;
          height: 52px;
        }
      }
    }

    .sc-news-analysis-stock-pick-container {
      padding-left: 60px;
      position: relative;
    }
  }
}

.sc-news-list-our-take-aways {
  background-image: url("../../img/grfx/grid-bg-big.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 32px 0;
  position: relative;

  .icon-see {
    position: absolute;
    top: -41px;
    left: 0;
  }

  .our-takeaways-swiper {
    .swiper-slide {
      width: clamp(calc(228px + 24px), 70vw, 286px);
    }

    .our-takeaways-swiper-item {
      .our-takeaways-swiper-item-thumbnail {
        width: 100%;
        height: clamp(228px, 70vw, 286px);
      }
    }
  }

  @include media-breakpoint-up(lg) {
    background-image: url("../../img/grfx/grid-bg-big-desktop.png");

    .icon-see {
      top: calc(-54px / 2);
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .our-takeaways-swiper {
      .swiper-slide {
        width: clamp(auto, auto, auto) !important;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .our-takeaways-swiper {
      padding: 0 !important;
    }
  }
}

.sc-news-analysis-latest-news {
  .news-analysis-latest-news {
    &.gap-60 {
      row-gap: 60px;
    }

    .news-analysis-latest-news-item {
      // width: clamp(312px, 86.66vw, 312px);

      .news-analysis-latest-news-item-thumbnail {
        height: clamp(342px, 86.66vw, 342px);
      }

      .news-analysis-latest-news-item-text {
        padding: 1rem;
        background-color: $cak-gray-2;
        flex: auto;

        p {
          color: rgba($white, 0.7);
        }
      }
    }
  }
}

.header-section-mobile {
  .header-section-icon {
    right: 7px;
  }
}

.grid-bg-left {
  background-image: url("../../img/grfx/grid-bg.svg");
  background-repeat: no-repeat;
  width: 195px;
  height: 195px;
  position: absolute;
  left: calc(-195px / 8);
  top: calc(-195px / 1.8);

  @include media-breakpoint-up(lg) {
    width: 255px;
    height: 255px;
    left: calc(-255px / 8);
    top: calc(-255px / 1.8);
  }
}

.sc-news-analysis-quick-take {
  position: relative;

  .sc-news-analysis-quick-take-sparks {
    position: absolute;
    top: 0;
    left: 0;

    img {
      position: absolute;

      &.yellow-diagonal {
        top: -30px;
        left: 1.5rem;
      }

      &.red-ring {
        top: 10px;
        transform: scale(0.9);
        left: calc(-23px / 2);
      }

      &.white-spark {
        top: 60px;
      }
    }
  }

  .sc-list-news-square {
    &.container {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .header-section-mobile {
    &.container {
      padding-right: 0 !important;
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: 5rem;
    padding-bottom: 5rem;

    .list-news-square-swiper {
      .swiper {
        .swiper-slide {
          width: 250px !important;
          height: 250px !important;
        }
      }
    }

    .sc-news-analysis-quick-take-sparks {
      top: 8rem;

      img {
        &.yellow-diagonal {
          left: 4rem;
        }

        &.red-ring {
          left: 2rem;
          transform: scale(1);
        }

        &.white-spark {
          transform: scale(0.5);
        }
      }
    }
  }
}

.sc-news-analysis-discover {
  overflow: hidden;

  .sc-news-analysis-discover-grid {
    position: absolute;
    top: 100px;
    right: -50px;

    @include media-breakpoint-up(lg) {
      top: 20px;
      right: -20px;
    }
  }
}