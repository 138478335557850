// Transitions
// Version 2.0.1

// Ease
$ease: (
  in-quad:      cubic-bezier(0.55, 0.085, 0.68, 0.53),
  in-cubic:     cubic-bezier(0.55, 0.055, 0.675, 0.19),
  in-quart:     cubic-bezier(0.895, 0.03, 0.685, 0.22),
  in-quint:     cubic-bezier(0.755, 0.05, 0.855, 0.06),
  in-sine:      cubic-bezier(0.47, 0, 0.745, 0.715),
  in-expo:      cubic-bezier(0.95, 0.05, 0.795, 0.035),
  in-circ:      cubic-bezier(0.6, 0.04, 0.98, 0.335),
  in-back:      cubic-bezier(0.6, -0.28, 0.735, 0.045),
  out-quad:     cubic-bezier(0.25, 0.46, 0.45, 0.94),
  out-cubic:    cubic-bezier(0.215, 0.61, 0.355, 1),
  out-quart:    cubic-bezier(0.165, 0.84, 0.44, 1),
  out-quint:    cubic-bezier(0.23, 1, 0.32, 1),
  out-sine:     cubic-bezier(0.39, 0.575, 0.565, 1),
  out-expo:     cubic-bezier(0.19, 1, 0.22, 1),
  out-circ:     cubic-bezier(0.075, 0.82, 0.165, 1),
  out-back:     cubic-bezier(0.175, 0.885, 0.32, 1.275),
  in-out-quad:  cubic-bezier(0.455, 0.03, 0.515, 0.955),
  in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1),
  in-out-quart: cubic-bezier(0.77, 0, 0.175, 1),
  in-out-quint: cubic-bezier(0.86, 0, 0.07, 1),
  in-out-sine:  cubic-bezier(0.445, 0.05, 0.55, 0.95),
  in-out-expo:  cubic-bezier(1, 0, 0, 1),
  in-out-circ:  cubic-bezier(0.785, 0.135, 0.15, 0.86),
  in-out-back:  cubic-bezier(0.68, -0.55, 0.265, 1.55),
);

@function ease($key) {
  @if map-has-key($ease, $key) {
    @return map-get($ease, $key);
  }
  @warn "Unknown '#{$key}' in $ease.";
  @return null;
}

// Base animation variable
$anim-duration: 1s;

// Reveal variables
$reveal-anim-duration: 1s;
$reveal-img-anim-duration: $reveal-anim-duration * 1.5;
$reveal-img-anim-delay: $reveal-anim-duration / 4;
$reveal-ease: ease(in-out-quart);

$reveal-block-bg: $dark;

// Ease
$ease-cubic-bezier:           cubic-bezier(0.55, 0.085, 0.68, 0.53) !default; // Antikode
$ease-in-out-cubic:           cubic-bezier(0.65, 0, 0.35, 1) !default; // Antikode
$ease-in-out-quart:           cubic-bezier(0.76, 0, 0.24, 1) !default; // Antikode

$transition-base:             all .2s $ease-cubic-bezier;
$transition-fade:             opacity .15s linear;
$transition-collapse:         height .35s ease;
$transition-opacity:          $transition-fade; // Antikode
$transition-color:            color .2s ease-in-out; // Antikode
$transition-background:       background-color .2s ease-in-out; // Antikode