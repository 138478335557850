.sc-search-header-container {
  z-index: 4;
  background-color: $cak-black;
  min-height: calc(100vh - 64px - 444px);

  @include media-breakpoint-up(lg) {
    min-height: calc(100vh - 72px - 216px);
  }
}

.sc-search-header {
  position: sticky;
  top: 1.5rem;
  background-color: $black;
  z-index: 2;
  padding-bottom: 1rem;
  background-color: $cak-black;

  .cak-input {
    .ic-right {
      &::after {
        content: "" !important;
      }
    }
  }
}

.sc-search-list {
  position: relative;
  z-index: 1;
  background-color: $cak-black;

  .list-search {
    // display: grid;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .top-search {
      @include responsiveToVW(font-size, 16px, 24px);
      margin-bottom: 0.25rem;
      font-weight: $font-weight-medium;
    }

    .search-item {
      position: relative;
      padding: 0 2rem 1rem 0;

      &:not(:last-child) {
        border-bottom: 1px solid rgba($white, 0.3);
      }

      .title {
        @include responsiveToVW(font-size, 12px, 14px);
        margin-bottom: 0.25rem;
        font-weight: $font-weight-normal;
        @include text-truncate-multiline(1);
        text-transform: uppercase;

        b {
          color: $yellow;
          text-decoration: underline;
        }
      }

      .subtitle {
        @include responsiveToVW(font-size, 18px, 20px);
        @include text-truncate-multiline(3);
        margin-bottom: 0;

        b {
          color: $yellow;
          text-decoration: underline;
        }
      }

      .icon-chevron {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate3d(0, -50%, 0);
      }
    }
  }

  .blank-search {
    .wrapper-blank {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      padding: 2rem 1rem;

      .blank-title {
        @include responsiveToVW(font-size, 18px, 24px);
        font-weight: $font-weight-semibold;
        margin-bottom: 0.25rem;
      }

      .blank-subtitle {
        @include responsiveToVW(font-size, 14px, 16px);
        margin-bottom: 0;
        opacity: 0.7;
      }
    }

    .divider {
      margin: 1.25rem 0;
      height: 4px;
      width: 100%;
      background-color: $gray-800;
    }
  }
}
