// ░█▀▀▄ ░█▀▀▀█ 　 ░█▄─░█ ░█▀▀▀█ ▀▀█▀▀ 　 ░█▀▀▀ ░█▀▀▄ ▀█▀ ▀▀█▀▀
// ░█─░█ ░█──░█ 　 ░█░█░█ ░█──░█ ─░█── 　 ░█▀▀▀ ░█─░█ ░█─ ─░█──
// ░█▄▄▀ ░█▄▄▄█ 　 ░█──▀█ ░█▄▄▄█ ─░█── 　 ░█▄▄▄ ░█▄▄▀ ▄█▄ ─░█──
// Override style on .cursor-custom.scss instead
// unless you want to upgrade the component version

// ========================================================================================================================================
// Cursor
// ========================================================================================================================================

.cursor {
  cursor: pointer;
  height: 4rem;
  width: 4rem;
  position: absolute;
  z-index: $zindex-top-1;
  background: $dark;
  top: 0;
  border-radius: 100%;
  pointer-events: none;
}
