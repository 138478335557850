.sc-marquee-stocks {
  width: 100%;
  border-top: 1px dashed $cak-light-blue;
  border-bottom: 1px dashed $cak-light-blue;
  font-family: $headings-font-family;
  .marquee-container {
    padding: 12px 0;
    background-color: $cak-dark-blue;
  }

  .marquee-item {
    border-right: 1px solid rgba($cak-light-blue, 0.3);
    padding-right: 1rem;
    margin-left: 1rem;
  }
}
