.sc-privacy-policy,
.sc-term-condition {
  min-height: calc(100vh - #{$navbar-height-mobile} - 450px);
  @include media-breakpoint-up(md) {
    min-height: calc(100vh - #{$navbar-height-desktop} - 195px);
  }
  .title {
    @include responsiveToVW(font-size, 30px, 32px);
    font-weight: $font-weight-semibold;
    margin-bottom: 1rem;
  }
  .content {
    h2 {
      @include responsiveToVW(font-size, 30px, 32px);
      font-weight: $font-weight-semibold;
      margin-bottom: 1rem;
    }
    strong,
    b {
      font-weight: $font-weight-semibold;
    }
    p {
      @include responsiveToVW(font-size, 14px, 16px);
      margin-bottom: 1rem;
      color: $white;
    }
  }
}
