.sc-list-news-square {
  .list-news-square-swiper {
    .swiper-slide {
      width: clamp(280px, 77.77vw, 286px) !important;
      height: clamp(280px, 77.77vw, 286px) !important;

      .list-news-square-swiper_item {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        &::before {
          content: "";
          bottom: 0;
          z-index: 1;
          height: 100%;
          width: 100%;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
          position: absolute;
        }

        .list-news-square-swiper_item-thumbnail {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }

        .list-news-square-swiper_item-badge {
          position: relative;
          padding: 8px 1rem;
          display: flex;
          align-items: center;
          // clip-path: polygon(0 0, 100% 0, 100% 85%, 90% 85%, 90% 100%, 0 100%);
          // background-color: $cak-blue;
          // z-index: 1;

          &::before {
            content: "";
            background-color: $cak-black;
            width: 102%;
            height: 105%;
            clip-path: polygon(0 0, 100% 0, 100% 85%, 90% 85%, 90% 100%, 0 100%);
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 1;
          }

          &::after {
            content: "";
            background-color: $cak-blue;
            width: 100%;
            height: 100%;
            clip-path: polygon(0 0, 100% 0, 100% 85%, 90% 85%, 90% 100%, 0 100%);
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
          }

          .badge-icon {
            z-index: 2;
          }

          span {
            z-index: 2;
            position: relative;
          }
        }

        .list-news-square-swiper_item-text {
          z-index: 1;
          padding: 14px 1rem;

          p {
            color: rgba($white, .7);
          }
        }
      }
    }
  }
}