// ========================================================================================================================================
// Navbar - Custom
// ========================================================================================================================================

.navbar {
  z-index: 1035 !important;
  .navbar-top {
    .col-left {
      margin-left: -0.75rem; // Set depending on hover style & padding

      @include media-breakpoint-up($navbar-expand-up) {
        margin-left: -0.75rem; // Set depending on hover style & padding
      }
    }

    .col-right {
      margin-right: -0.75rem; // Set depending on hover style & padding

      @include media-breakpoint-up($navbar-expand-up) {
        margin-right: -0.75rem; // Set depending on hover style & padding
      }
    }
  }

  .navbar-main {
    .btn-join-mobile {
      z-index: 1034;
      position: relative;

      .spark-grfx {
        position: absolute;
        top: 0;
        right: 0;
        width: 135%;
        height: 100%;

        .yellow {
          width: 15px;
          height: 15px;
          position: absolute;
          top: 5px;
          left: 14px;
        }

        .plus {
          position: absolute;
          left: 6px;
          top: 20px;
        }

        .pixel {
          position: absolute;
          bottom: 10px;
          right: -12px;
        }
      }
    }

    .col-left {
      margin-left: -0.75rem; // Set depending on hover style & padding

      @include media-breakpoint-up($navbar-expand-up) {
        margin-left: -0.75rem; // Set depending on hover style & padding
      }
    }

    .col-right {
      margin-right: -0.75rem; // Set depending on hover style & padding

      @include media-breakpoint-up($navbar-expand-up) {
        margin-right: 0; // Set depending on hover style & padding
      }
    }
  }
}

.navbar-brand {
  padding-left: 0.75rem;
  width: 85px;
  cursor: pointer;

  @include media-breakpoint-up($navbar-expand-up) {
    width: 100px;
  }
}

// ========================================================================================================================================
// Mobile
// ========================================================================================================================================

// ==========================================================================
// Mobile - Navbar Main
// ==========================================================================

.navbar-main {
  // background-color: $cak-black !important;

  .col-left,
  .col-right {
    .nav-item {
      width: 3rem;
    }

    .nav-icon {
      // prettier-ignore
      .ais,
      .aib,
      .ail,
      .air,
      .icon {
        top: 1px;
      }

      i {
        font-size: 1.125rem;
      }
    }
  }
}

.nav-toggler {
  .icon-bar {
    width: 22px;
    border-radius: 2px;
    height: 2px; // Alt 1

    // height: 1px; // Alt 2
    & + .icon-bar {
      margin-top: 4px; // Alt 1
      // margin-top: 5px; // Alt 2
    }
  }
}

// ==========================================================================
// Mobile - Menu
// ==========================================================================

// Main
.mobile-menu,
.mobile-menu-child {
  // @include responsiveToVW(width, 304px, 304px);
  width: calc(100% - 56px);
  position: fixed;
  top: 0;
  // padding-right: 56px;
  z-index: 1035;
  // &::before {
  //   content: "";
  //   width: 56px;
  //   height: 100%;
  //   background-color: rgba($black, 0.2);
  //   position: absolute;
  //   top: 0;
  //   right: -56px;
  // }
  // left: 0 !important;
  // right: auto !important;
  .mobile-menu-content {
    padding: 1rem 0;

    .mobile-menu-content-inside {
      overflow-y: auto;
      height: calc(80vh - 95px);
      height: -webkit-calc(80vh - 95px);
    }
  }

  .nav-item {
    overflow: hidden;

    .nav-link {
      // font-size: 1.5rem;
      font-family: $headings-font-family;
      @include responsiveToVW(font-size, 28px, 28px);
      font-weight: $font-weight-semibold;

      &.branch-item {
        @include responsiveToVW(font-size, 24px, 24px);
      }
    }

    .nav-link-2 {
      @include responsiveToVW(font-size, 18px, 18px);
      font-weight: $font-weight-semibold;
      color: $cak-black;
    }

    &.nav-item-2 {
      margin-bottom: 1rem;
    }
  }

  hr {
    width: 100%;
    border-bottom: 1px solid rgba($cak-black, 0.2);
    margin-top: 18px;
    margin-bottom: 30px;
  }

  .mobile-menu-socmed {
    position: absolute;    
    bottom: 34px;
    left: 24px;      
    transform: translateX(0%);
    transition: .8s;
    opacity: 1;
    transition-delay: .1s;

    &.mobile-menu-socmed-hide {
      opacity: 0;
      transform: translateX(-120%);
    }

    .navbar-nav {
      flex-direction: row !important;
      gap: 1rem !important;

      .nav-item {
        .nav-link {
          padding: 0 !important;
        }
      }
    }

    &:active {
      .back-btn-arrow,
      p {
        transform: scale(0.97);
      }
    }

    .back-btn-arrow {
      background-color: $cak-orange;
      @include responsiveToVW(width, 36px, 36px);
      @include responsiveToVW(height, 36px, 36px);
      border-radius: 50%;
      box-shadow: 0px 4px $cak-black;
      border: 1px solid $cak-black;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 0.35s;
    }

    p {
      transition: transform 0.35s;
    }
  }
}

// ========================================================================================================================================
// Desktop
// ========================================================================================================================================

// ==========================================================================
// Desktop - Navbar Top
// ==========================================================================

.navbar-top {
  .nav-link {
    padding: 0 0.75rem;

    i {
      font-size: 1rem;
    }
  }
}

// ==========================================================================
// Desktop - Navbar Main
// ==========================================================================

.navbar-main {
  .desktop-menu {
    position: relative;
    align-items: center;
    width: 88%;

    .navbar-nav {
      gap: 1rem;
    }

    .nav-item {
      // margin-left: 1rem;
      margin-left: 0;

      .nav-link {
        i {
          font-size: 1.125rem;
        }
      }
    }

    .nav-item-search {
      flex: 1;
      // width: 100%;
    }
  }
}

// ========================================================================================================================================
// Search
// ========================================================================================================================================

.search-menu {
  background: transparent;
}

.form-search {
  .form-control {
    border-color: transparent;
    border-radius: 2rem;
    padding-left: 2.75rem;
    background: $white;
  }

  .form-search-icon {
    font-size: 1.25rem;
    left: 0.75rem;
  }

  .form-search-close {
    right: 0.7rem;
    background: $gray-100;
    border-radius: 2rem;
    font-size: 0.875rem;
    cursor: pointer;

    @include hover-focus-active {
      background: $gray-200;
    }
  }
}

// Effect (Expand)
.search-menu {
  &.expand {
    .form-group {
      @include media-breakpoint-up($navbar-expand-up) {
        right: 120px;
      }

      .form-search {
        width: 80px;
      }
    }

    &.hide {
      .form-search {
        width: 80px;
      }
    }

    &.show {
      .form-search {
        width: 100%;
      }

      @include media-breakpoint-up($navbar-expand-up) {
        .form-search {
          width: 400px;
        }
      }
    }
  }
}

// ==========================================================================
// Color Theme
// ==========================================================================

// prettier-ignore
.navbar {
  transition: $transition-base;
  &.navbar-light {
    .navbar-brand {
      .logo-light {
        display: none;
      }

      .logo-dark {
        display: block;
      }
    }

    .nav-toggler {
      .icon-bar {
        background-color: $dark;
      }
    }

    .navbar-top {
      background: $gray-100;

      .nav-item {
        .nav-link {
          color: $primary;

          &:hover {
            color: darken($primary, 10%);
          }
        }
      }
    }

    .navbar-main {
      background: $white;

      .nav-item {
        .nav-toggler .icon-bar {
          background: $primary;

          &:hover {
            background: darken($primary, 10%);
          }
        }

        .nav-link,
        .nav-icon {
          color: $primary;

          &:hover {
            color: darken($primary, 10%);
          }
        }
      }
    }
  }

  &.navbar-dark {
    .navbar-brand {
      .logo-dark {
        display: none;
      }

      .logo-light {
        display: block;
      }
    }

    .navbar-top {
      background: $gray-800;

      .nav-item {
        .nav-link {
          color: $white;

          &:hover {
            color: $primary;
          }
        }
      }
    }

    .navbar-main {
      background: $dark;

      .nav-item {
        .nav-toggler .icon-bar {
          background: $white;

          &:hover {
            background: $white;
          }
        }

        .nav-link,
        .nav-icon {
          color: $white;
          font-family: $headings-font-family;
          font-weight: $font-weight-medium;

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }

  &.transparent {
    .navbar-main {
      background: transparent;
    }
  }

  &.is-scrolled {
    overflow: hidden;
    .navbar-main {
      background: $cak-black;
    }
  }
  &.hide-misc {
    overflow: hidden;
  }
}

.mobile-menu,
.mobile-menu-child {
  // background: $dark;
  background: $white;
  .nav-item {
    .nav-link {
      // color: $white;
      color: $cak-black;
      padding: 12px 0;

      &:hover {
        color: $primary;
      }

      .icon-nav-link {
        width: 2rem;
        height: 2rem;
      }
    }

    @for $i from 1 through $mobile-menu-anim-count {
      // Initial
      // .anim-only-#{$i} {
      //   transform: translateY(100%) !important;
      //   transition-property: all !important;
      //   transition-duration: 0 !important;
      //   transition-timing-function: $mobile-menu-ease !important;
      // }
      // Hide
      &.hide-only {
        .anim-only-#{$i} {
          transform: translateY(100%) !important;
          transition-property: all !important;
          transition-timing-function: $mobile-menu-ease !important;
          transition-duration: $mobile-menu-duration !important;
          transition-delay: #{($mobile-menu-anim-count / 10) -
            $i *
            0.1}
            s !important;
        }
      }

      // Show
      &.show-only {
        .anim-only-#{$i} {
          transform: translateY(0) !important;
          transition-property: all !important;
          transition-duration: $mobile-menu-duration !important;
          transition-delay: #{$mobile-menu-delay + ($i * 0.1)}s !important;
          transition-timing-function: $mobile-menu-ease !important;
        }
      }
    }
  }

  .cak-input {
    .form-control-wrapper {
      border-color: rgba($cak-black, 0.2) !important;
    }
  }
}

.big-submenu {
  background-color: $cak-gray-1;
  width: clamp(360px, 92.08vw, 1326px);
  height: 100vh;
  position: fixed;
  z-index: 1034;
  top: 0;
  left: 0;
  padding: 100px;

  &::before {
    content: "";
    background-color: $cak-black;
    width: 100%;
    position: absolute;
    height: 100vh;
    top: 0;
    right: -100%;
  }

  .big-submenu-close {
    position: absolute;
    right: calc(-106px / 2);
    top: 60px;
  }

  .sparks-submenu {
    position: absolute;
    bottom: 90px;
    right: 90px;
  }
}

.grid-bg {
  position: absolute;
  top: -5rem;
  right: -37px;
  @include media-breakpoint-up($navbar-expand-up) {
    right: -30px;
    z-index: -1;
  }
  background-image: url("../../../assets/img/grfx/grid-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 195px;
  height: 195px;
  z-index: 0;
}
