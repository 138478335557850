// ░█▀▀█ ░█▀▀▀ ░█▀▄▀█ ░█▀▀▀█ ░█──░█ ░█▀▀▀ 　 ▀█▀ ░█▀▀▀ 　 ░█─░█ ░█▄─░█ ░█─░█ ░█▀▀▀█ ░█▀▀▀ ░█▀▀▄
// ░█▄▄▀ ░█▀▀▀ ░█░█░█ ░█──░█ ─░█░█─ ░█▀▀▀ 　 ░█─ ░█▀▀▀ 　 ░█─░█ ░█░█░█ ░█─░█ ─▀▀▀▄▄ ░█▀▀▀ ░█─░█
// ░█─░█ ░█▄▄▄ ░█──░█ ░█▄▄▄█ ──▀▄▀─ ░█▄▄▄ 　 ▄█▄ ░█─── 　 ─▀▄▄▀ ░█──▀█ ─▀▄▄▀ ░█▄▄▄█ ░█▄▄▄ ░█▄▄▀
// When starting real project, remove this along with the pages demo files.

// ========================================================================================================================================
// Demo
// ========================================================================================================================================

.sc-demo-details__header {
  background: $gray-50;
  margin-bottom: 1rem;
  h1 {
    a {
      position: relative;
      padding-left: 1rem;
      @include ic-left($icon: $ai-angle-left);
      &:before {
        left: 0;
      }
    }
  }
}

.sc-demo-details__content {
  border-bottom: 4px solid $gray-50;
  p {
    color: $body-color-secondary;
  }
  section {
    padding: 1.5rem 0;
    &:last-child {
      padding-bottom: 0;
    }
  }
}
