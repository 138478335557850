.sc-contact-header {
  .container-menu {
    border-bottom: 1px solid rgba($white, 0.3);
    @include media-breakpoint-up(md) {
      border-bottom: none;
    }
    .wrapper-menu {
      display: grid;
      grid-template-columns: 1fr 1fr;
      @include media-breakpoint-up(md) {
        border-bottom: 1px solid rgba($white, 0.3);
        grid-template-columns: 212px 212px;
      }
      .menu-item {
        border-bottom: 2px solid transparent;
        padding: 0.5rem;
        text-align: center;
        .title-menu {
          @include responsiveToVW(font-size, 16px, 16px);
          margin-bottom: 0;
          opacity: 0.7;
          font-weight: $font-weight-semibold;
        }
        &.active {
          border-color: $yellow;
          .title-menu {
            opacity: 1;
          }
        }
      }
    }
  }
}

.sc-contact-join {
  padding: 1.5rem 0;
  .title {
    @include responsiveToVW(font-size, 28px, 44px);
    margin-bottom: 0.75rem;
    font-weight: $font-weight-semibold;
    @include media-breakpoint-up(md) {
      margin-bottom: 1rem;
    }
  }
  .subtitle {
    @include responsiveToVW(font-size, 14px, 16px);
    margin-bottom: 1rem;
    @include media-breakpoint-up(md) {
      max-width: 250px;
    }
  }
  .title-benefit {
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
      font-weight: $font-weight-semibold;
      font-size: 1.5rem;
    }
  }
  .list-benefit {
    display: grid;
    gap: 1rem;
    margin-bottom: 1rem;
    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 1fr;
      gap: 2.5rem;
      margin-bottom: 3rem;
    }
    .benefit-item {
      display: grid;
      grid-template-columns: 44px 1fr;
      align-items: center;
      gap: 1rem;
      .text-benefit {
        @include responsiveToVW(font-size, 16px, 20px);
        font-weight: $font-weight-medium;
        margin-bottom: 0;
      }
    }
  }
  form {
    @include media-breakpoint-up(md) {
      // display: grid;
      // grid-template-columns: 5fr 3fr;
      // gap: 2.5rem;
      // align-items: flex-end;
      .btn {
        height: 48px;
      }
    }
  }
}

.sc-contact-feedback {
  padding: 1.5rem 0;
  .title {
    @include responsiveToVW(font-size, 28px, 44px);
    margin-bottom: 0.75rem;
    font-weight: $font-weight-semibold;
    @include media-breakpoint-up(md) {
      margin-bottom: 1rem;
    }
  }
  .subtitle {
    @include responsiveToVW(font-size, 14px, 16px);
    margin-bottom: 1rem;
    @include media-breakpoint-up(md) {
      max-width: 350px;
    }
  }
  .btn-submit {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 25%;
    }
  }
}
