// ========================================================================================================================================
// Modal
// ========================================================================================================================================

// Add custom styles here
.modal-video {
  .close {
    color: $white;
  }
  .modal-wrapper {
    background-color: $black;
    height: 100%;
    max-height: 100vh;
    @include media-breakpoint-up(md) {
      max-height: calc(100vh - 4rem);
    }
    .modal-content {
      justify-content: center;
      align-items: center;
      height: 100%;
      background-color: $black;
      .img-fluid {
        max-height: 100vh;
        @include media-breakpoint-up(md) {
          max-height: calc(100vh - 4rem);
        }
      }
    }
  }
}
