.bottom-nav {
  @include responsiveToVW(width, 64px, 64px);
  @include responsiveToVW(height, 64px, 64px);
  background-color: $cak-red;  
  box-shadow: 0px 4px $cak-black;
  border: 1px solid $cak-black;
  position: fixed;
  bottom: 1rem;
  right: 1.5rem;
  border-radius: 50%;
  overflow: hidden;
  transition: .3s;
  z-index: 1036;
  display: flex;
  justify-content: center;
  align-items: center;

  &.inactive {
    background-color: $white !important;
    box-shadow: none !important;
    .nav-toggler {
      .icon-bar {
        background-color: $cak-black  !important;
      }

      .top-bar {
        transform: rotate(0);
      }

      .middle-bar {
        opacity: 1;
      }

      .bottom-bar {
        transform: rotate(0);
      }
    }
  }

  .nav-icon {
    // height: $navbar-main-height-mobile;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 7px;
    cursor: pointer;

    // prettier-ignore
    .ais,
    .aib,
    .ail,
    .air,
    .icon {
      position: relative;
      top: 1px;
    }

    &.absolute {
      position: absolute;
      z-index: $zindex-top-2;
    }

    &.fixed {
      position: fixed;
      z-index: $zindex-top-2;
    }

    i {
      font-size: 1.125rem;
    }
  }

  .nav-toggler {
    .icon-bar {
      display: block;
      background-color: $white;
      width: 34px;
      border-radius: 2px;
      transition: all 1s ease(in-out-quart);
      height: 2px; // Alt 1
      margin: 0px !important;
      // height: 1px; // Alt 2
      // & + .icon-bar {
      //   // margin-top: 4px; // Alt 1
      //   // margin-top: 5px; // Alt 2
      // }
    }

    .top-bar {
      transform: rotate(45deg) translate(4px, -5px);
      transform-origin: 10% 10%;
    }

    .middle-bar {
      opacity: 0;
    }

    .bottom-bar {
      transform: rotate(-45deg) translate(5px, 4px);
      transform-origin: 10% 90%;
    }
  }
}