.sc-glossary-header {
  position: sticky;
  top: 1.5rem;
  background-color: $black;
  z-index: 2;
  .container-list {
    margin: 1.5rem auto;
    @include media-breakpoint-down(sm) {
      border-bottom: 1px solid rgba($white, 0.3);
      padding: 0 !important;
    }
    &.blank-list {
      display: none;
    }
    .list-letter {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;
      padding: 0 1.25rem;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
      @include media-breakpoint-up(md) {
        // justify-content: space-between;
        border-bottom: 1px solid rgba($white, 0.3);
      }
      .letter-item {
        border-bottom: 2px solid transparent;
        padding: 0.5rem;
        text-align: center;
        .letter {
          @include responsiveToVW(font-size, 16px, 16px);
          margin-bottom: 0;
          opacity: 0.7;
          font-weight: $font-weight-semibold;
        }
        &.active {
          border-color: $yellow;
          .letter {
            opacity: 1;
          }
        }
      }
    }
  }
}

.sc-glossary-list {
  position: relative;
  z-index: 1;
  padding-top: 1.75rem;
  min-height: calc(100vh - 64px - 153px - 444px - 28px) !important;

  @include media-breakpoint-up(lg) {
    min-height: calc(
      100vh - 72px - 153px - 135px - 61px - 28px - 28px
    ) !important;
  }

  .list-glossary {
    display: grid;
    gap: 1rem;
    .top-search {
      @include responsiveToVW(font-size, 16px, 24px);
      margin-bottom: 0.25rem;
      font-weight: $font-weight-medium;
    }
    .glossary-item {
      position: relative;
      padding: 0 2rem 1rem 0;
      &:not(:last-child) {
        border-bottom: 1px solid rgba($white, 0.3);
      }
      .title {
        @include responsiveToVW(font-size, 18px, 24px);
        margin-bottom: 0.25rem;
        font-weight: $font-weight-normal;
      }
      .subtitle {
        @include responsiveToVW(font-size, 12px, 16px);
        @include text-truncate-multiline(2);
        margin-bottom: 0;
        position: relative;
        max-height: 39px;
        @include media-breakpoint-up(lg) {
          max-height: 48px;
        }
        p {
          margin-bottom: 0;
        }
      }
      .icon-chevron {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate3d(0, -50%, 0);
      }
    }
  }
  .blank-glossary {
    .wrapper-blank {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      padding: 2rem 1rem;
      .blank-title {
        @include responsiveToVW(font-size, 18px, 24px);
        font-weight: $font-weight-semibold;
        margin-bottom: 0.25rem;
      }
      .blank-subtitle {
        @include responsiveToVW(font-size, 14px, 16px);
        margin-bottom: 0;
        opacity: 0.7;
      }
    }
    .divider {
      margin: 1.25rem 0;
      height: 4px;
      width: 100%;
      background-color: $gray-800;
    }
  }
}

.sc-glossary-detail {
  .container {
    position: relative;
    .img-quote {
      position: absolute;
      top: 3.5rem;
      right: 1.25rem;
      @include media-breakpoint-up(md) {
        right: 0;
      }
    }
    .label {
      @include responsiveToVW(font-size, 12px, 14px);
      color: $yellow;
      text-transform: uppercase;
      margin-bottom: 0.25rem;
    }
    .title {
      @include responsiveToVW(font-size, 30px, 36px);
      margin-bottom: 1rem;
      font-weight: $font-weight-semibold;
    }
    .wrapper-img {
      margin-bottom: 1.5rem;
      .img-glossary {
        width: 100%;
      }
    }
    .content {
      p {
        @include responsiveToVW(font-size, 16px, 18px);
      }
    }
  }
}
